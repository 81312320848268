import axios from "axios";
import { Api } from "../api";
import AxiosErrorService from "@/service/AxiosErrorService";

const LoginRepository = {
    /**
     * getStoragePid
     *     
     * @returns {Object} server response
     */  
    async getStoragePid() {
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_STPID)
            console.log('getStoragePid res', res)
            return res;
        } catch(e){
            console.log('getStoragePid error:', e);
            return {}
        }
    },
    /**
     * loginUser
     * 
     * @param {Object} loginData
     * 
     * @returns {Object} server response
     */  
    /* async loginUser(loginData : any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.LOGIN_ACTION, loginData)
        .then((response:any) => {
         console.log('loginUser res', response)
            return response;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });        
    }, */

    /**
     * loginUser
     * 
     * @param {Object} loginData
     * 
     * @returns {Object} server response
     */  
    async loginUser(loginData : string) {
        const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.LOGIN_ACTION, loginData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('loginResponse', res)
        return res;
    },

    /**
     * logoutUser
     * 
     * 
     * @returns {Object} server response
     */ 
     async logoutUser() {
        try{
            const res = await axios.create({ withCredentials: true }).post(Api.BACKEND_URL + Api.LOGOUT_ACTION,"logintype=logout")
            console.log('check logout Response', res)
            return res;
        } catch(e){
            console.log('logout user error:', e);
            return {}
        }
    },
       
}
export default LoginRepository;