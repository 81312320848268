export default class Workarea {
    uid : number;
    number : string;
    title: string;
    department : any;
    unit : any;
  
    constructor(uid: number, number:string, title: string, department : any, unit : any) {
        this.uid = uid;
        this.number = number;
        this.title = title;
        this.department = department;
        this.unit = unit;
    }

    static createWorkareasFromResponse(res:any) {
        const workareas :any = [];
        res.data.workareas.forEach((element: any) => {
            workareas.push(new Workarea (element.uid, element.number, element.title, element.department, element.unit)
        )})
        return workareas;
    }

    static createWorkareaFromResponse(res:any) {
        //console.log('Workarea[createWorkareaFromResponse] -> res', res);
        return new Workarea (res.uid, res.number, res.title, res.department, res.unit);
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getTitle() {
    return this.title;
    }

    setTitle(title:string) {
        this.title = title;
    }

    getDepartment() {
        return this.department;
    }

    setDepartment(department:string) {
        this.department = department;
    }
}