export default class Contact {
    uid: number;
    phone: string;
    email: string;

    constructor(
        uid : number, email:string, phone:string
    ){
        this.uid = uid;
        this.email = email
        this.phone = phone
    }

    static createContactsFromResponse(res:any) {
        const contacts :any = [];
        res.data.contacts.forEach((element: any) => {
            contacts.push(new Contact(element?.uid, element?.email, element?.phone)
        )})
        return contacts;
    }

    static createContactFromResponse(res:any) {
        return new Contact(
            res?.uid, res?.email, res?.phone
        );
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }
 
     setUid(uid:number) {
         this.uid = uid;
     }  

     getEmail() {
        return this.email;
     }
 
     setEmail(email:string) {
         this.email = email;
     } 

     getPhone() {
        return this.phone;
     }
 
     setPhone(phone:string) {
         this.phone = phone;
     }        
}