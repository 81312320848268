import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "list-empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ww-list", {'raised': _ctx.raised}])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.isEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.emptyStateText), 1))
      : _createCommentVNode("", true)
  ], 2))
}