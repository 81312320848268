import axios from "axios";
import { Api } from "@/api/";
import { ObjectStorage, Client } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class ClientRepository {
    static clients = new ObjectStorage([]); 

    static async fetchClients() {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_CLIENTS_ACTION)
        .then((response:any) => {
            //console.log('fetchClients res', response)
            this.clients = new ObjectStorage(Client.createClientsFromResponse(response));
            result = this.clients;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getClientById(uid:any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_CLIENT_BY_ID_ACTION + uid)
        .then((response:any) => {
            result = {
                client: Client.createClientFromResponse(response.data.customer)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async createClient(postData:any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_CLIENT_ACTION, postData)
        .then((response:any) => {
            //console.log("createClient res", response)
            result = {
                client: Client.createClientFromResponse(response.data.customer)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async updateClient(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.UPDATE_CLIENT_ACTION, postData)
        .then((response:any) => {
            result = {
                client: Client.createClientFromResponse(response.data.customer)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async toggleClient(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.TOGGLE_CLIENT_ACTION, postData)
        .then((response:any) => {
            result = {
                client: Client.createClientFromResponse(response.data.customer)
            }
            if (result.client) {
                this.clients.replaceElement(result.client as any)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async deleteClient(postData:any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.DELETE_CLIENT_ACTION, postData)
        .then((response:any) => {
            //console.log("deleteClient res", response)
            result = {
                client: Client.createClientFromResponse(response.data.customer)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }
}