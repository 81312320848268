import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ww-checkbox",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCheckboxState && _ctx.toggleCheckboxState(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["ww-custom-checkbox", {'isChecked': _ctx.value}])
    }, [
      (_ctx.modelValue)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "svgicon",
            innerHTML: _ctx.store.getters.svgIcons.check
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
  ]))
}