export default class Presence {
    uid: number
    come: number
    go: number
    comeWorkstation: number
    goWorkstation: number
    employee: number

    constructor(uid: number, come: number, go: number, comeWorkstation: number, goWorkstation: number, employee: any) {
        this.uid = uid
        this.come = come
        this.go = go
        this.comeWorkstation = comeWorkstation
        this.goWorkstation = goWorkstation
        this.employee = (typeof(employee) === 'number' ? employee : employee.uid)
    }

    static createPresencesFromResponse(res:any) {

        const presences :any = [];
        res.data.presences.forEach((element: any) => {  
            presences.push(new Presence(element.uid, element.come, element.go, element.comeWorkstation, element.goWorkstation, element.employee )
        )})
        return presences;
    }    

    static createPresenceFromResponse(res:any) {
        //console.log('Presence[createPresenceFromResponse] -> res', res);
        return new Presence (res.uid, res.come, res.go, res.comeWorkstation, res.goWorkstation, res.employee );
    }

    getUid() {
        return this.uid;
     }
 
     setUid(uid:number) {
         this.uid = uid;
     }  

     getCome() {
        return this.come;
     }

     setCome(come:number) {
         this.come = come;
     }  

     getGo() {
        return this.go;
     }
 
     setGo(go:number) {
         this.go = go;
     }  

     getComeWorkstation() {
        return this.comeWorkstation;
     }
     
     setComeWorkstation(comeWorkstation:number) {
         this.comeWorkstation = comeWorkstation;
     }  

     getGoWorkstation() {
        return this.goWorkstation;
     }
 
     setGoWorkstation(goWorkstation:number) {
         this.goWorkstation = goWorkstation;
     }  

     getEmployee() {
        return this.employee
     }
 
     setEmployee(employee:number) {
         this.employee = employee;
     }       
}