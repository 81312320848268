export default class Department {
    uid: number;
    title: string;
    number: number;

    constructor(uid: number, title: string, number: number) {
        this.uid = uid;
        this.title = title;
        this.number = number;
    }
    
    static createDepartmentsFromResponse(res:any) {
        const departments :any = [];
        res.data.departments.forEach((element: any) => {
            departments.push(new Department (element.uid, element.title, element.number)
        )})
        return departments;
    }
    static createDepartmentFromResponse(res:any) {
        //console.log('Department[createDepartmentFromResponse] -> res', res);
        return new Department (res.uid, res.title, res.number);
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
        }
    
        setUid(uid:number) {
            this.uid = uid;
        }  

        getTitle() {
        return this.title;
        }
    
        setTitle(title:string) {
            this.title = title;
        }  

        getNumber() {
        return this.number;
        }
    
        setNumber(number:number) {
            this.number = number;
        }
}