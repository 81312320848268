import axios from "axios";
import { Api } from "../api";
import AxiosErrorService from "@/service/AxiosErrorService";

const UserRepository = {

    /**
     * forgotpassword
     * 
     * @param {string} userData
     * 
     * @returns {Object} server response
     */  
    async forgotPassword(userData : string) {
        const res = await axios.post(Api.BACKEND_URL + Api.FORGOT_PASSWORD, userData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('forgotpassword', res)
        return res;
    },

     /**
     * resetPassword
     * 
     * @param {string} postData
     * 
     * @returns {Object} server response
     */ 
    async resetPassword(postData: string) {
        const res = await axios.post(Api.BACKEND_URL + Api.RESET_PASSWORD, postData).catch((error) => {
            return AxiosErrorService.handleError(error);
        });
        console.log('resetpassword', res)
        return res;
    },   

}
export default UserRepository;