
import store from '@/store/index';
import { defineComponent } from 'vue';
import { Button, IconButton } from '@/ui/index';

export default defineComponent({
    name: "Dialog",
    components: { Button, IconButton },
    props: {
        submitting:{
            type: Boolean,
            default: false
        },
        headline: {
            type: String,
            default: ""
        },
        confirmCaption: {
            type: String,
            default: ""
        },
        hideCancel:{
            type: Boolean,
            default: false
        },
        confirmDisabled:{
            type: Boolean,
            default: false
        },
        noActions:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            store
        }
    },
    mounted() {
        document.addEventListener('keydown', this.onDocumentKeyDown);
    },
    unmounted() {
        document.removeEventListener('keydown', this.onDocumentKeyDown);
    },
    methods:{
        onDocumentKeyDown(e : any){
            if (e.key == "Escape" || e.keyCode == 27) {
                this.onCancelClick(e);
            }
        },
        onSubmitClick(e:Event) {
            this.$emit('onSubmit', e);
        },
        onCancelClick(e:Event) {
            this.$emit('onCancel', e);
        }
    }
})
