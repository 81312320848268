export default class Employeegroup {
    uid: number;
    title: string;
    systemGroup: boolean;
    isAdmin: boolean;
    isDefault: boolean;
    rights: string;

    constructor(
        uid : number, title:string, systemGroup: boolean, isAdmin: boolean, isDefault: boolean, rights: string
    ){
        this.uid = uid;
        this.title = title;
        this.systemGroup = systemGroup;
        this.isAdmin = isAdmin;
        this.isDefault = isDefault;
        this.rights = rights;
    }

    static createEmployeegroupsFromResponse(res:any) {
        const groups :any = [];
        res.data.employeegroups.forEach((element: any) => {
            groups.push(new Employeegroup(element?.uid, element?.title, element?.systemGroup, element?.isAdmin, element?.isDefault, element?.rights)
        )})
        return groups
    }

    static createEmployeegroupFromResponse(res:any) {
        return new Employeegroup(
            res?.uid, res?.title, res?.systemGroup, res?.isAdmin, res?.isDefault, res?.rights
        );
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }
 
     setUid(uid:number) {
         this.uid = uid;
     }  

     getTitle() {
        return this.title;
     }
 
     setTitle(title: string) {
         this.title = title;
     } 

     getSystemGroup() {
        return this.systemGroup;
     }
 
     setSystemGroup(systemGroup:boolean) {
         this.systemGroup = systemGroup;
     }        

     getIsAdmin() {
        return this.isAdmin;
     }
 
     setIsAdmin(isAdmin:boolean) {
         this.isAdmin = isAdmin;
     }             

     getIsDefault() {
        return this.isDefault;
     }
 
     setIsDefault(isDefault:boolean) {
         this.isDefault = isDefault;
     }             

     getRights() {
        return this.rights;
     }
 
     setRights(rights: string) {
         this.rights = rights;
     }      

     getRightsArray() {
        return (this.rights ? JSON.parse(this.rights) : [])
     }
}