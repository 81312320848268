//import { DataService } from '../utils';
import { EmployeegroupRepository } from '@/repository';

const EmployeegroupController = {

    async fetchEmployeegroups() {
        const res : any = await EmployeegroupRepository.fetchEmployeegroups();
        return res;
    },

    async getEmployeegroupById(uid:any) {
        const res : any = await EmployeegroupRepository.getEmployeegroupById(uid);
        return res;
    },

    /*async getEmployeeTimeTables(uid:any) {
        //console.error("WARNING: static data from EmployeeController[getEmployeeTimeTables]");
        const timeTables = [
            { uid: 1, date: "Jänner 2023", file: "link" },
            { uid: 2, date: "Dezember 2022", file: "link" }
        ]
        return timeTables;
    },
    async fetchEmployeeGroups() {
        const res : any = await EmployeeRepository.fetchEmployeeGroups();
        return res;
    }, */

    async createEmployeegroup(formData : any) {
        const rights = (formData.rights ? JSON.stringify(formData.rights) : '')
        const postData = `title=${formData.title}&rights=${rights}`
        //const postData = new FormData();
        //postData.append('image', image)
        //postData.append('type', 'profile')
        /* Object.keys(formData).forEach(key => {
            if (formData[key]) {
                postData.append(key, formData[key]);
            }
        }); */
        const res : any = await EmployeegroupRepository.createEmployeegroup(postData)
        return res;
    },

    async updateEmployeegroup(formData : any) {
        /* const postData = new FormData()
        Object.keys(formData).forEach(key => {
            if (formData[key]) {
                postData.append(key, formData[key]);
            }
        }); */        
        const rights = (formData.rights ? JSON.stringify(formData.rights) : '')
        const postData = `uid=${formData.uid}&title=${formData.title}&rights=${rights}`
        const res : any = await EmployeegroupRepository.updateEmployeegroup(postData);
        return res;
    },
    /* async deleteEmployee(employeeId : number) {
        const postData = `uid=${employeeId}`;
        const res : any = await EmployeeRepository.deleteEmployee(postData);
        return res
    } */
}
export default EmployeegroupController;
