import axios from "axios";
import { Api } from "@/api/";
import { ObjectStorage, Right } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class RightRepository  {
    static rights = new ObjectStorage([]); 

    static async fetchRights (){
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_RIGHTS_ACTION)
        .then((response:any) => {
         console.log('fetchRights res', response)
            this.rights = new ObjectStorage(Right.createRightsFromResponse(response));
            result = this.rights;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }
}

