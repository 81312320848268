
import { defineComponent } from 'vue';
import store from '@/store/index';

export default defineComponent({
    name: "Card",
    props:{
        title:{
            type: String, 
            default: ""
        },
        contentLoading: {
            type: Boolean, 
            default: false
        }
    },
    data() {
        return{
            store
        }
    },

})
