const Converter =  {

    convertMilliSecondsToTime(milliseconds:number) {
        let hours : any = Math.floor(milliseconds / 3600000);
        let minutes : any = Math.floor((milliseconds - (hours * 3600000)) / 60);
        let timestring = "";
        if (hours < 10) { hours = "0" + hours; }
            timestring += hours + ':';
        if (minutes < 10) { minutes = "0" + minutes; }
            timestring += minutes;
        return timestring + " h";
    },

    convertSecondsToTime(seconds:number) {
        let hours : any = Math.floor(seconds / 3600);
        let minutes : any = Math.floor((seconds - (hours * 3600)) / 60);
        let timestring = "";
        if (!isNaN(hours)) {
            if (hours < 10) { hours = "0" + hours; }
            timestring += hours + ':';
        }
        if (!isNaN(minutes)) {
            if (minutes < 10) { minutes = "0" + minutes; }
            timestring += minutes;
        }
        return (timestring ? timestring + " h" :  '00:00')
    },

    convertTimeStampToDate(tstamp:number) {
        const date = new Date(tstamp * 1000);
        let datestring = "";
        if (date.getDate() < 10) { datestring += "0" + date.getDate() + "."; } else { datestring+= date.getDate() + "."}
        if (date.getMonth() < 10) { datestring += "0" + date.getMonth() + "."; } else { datestring+= date.getMonth() + "."}
        if (date.getFullYear() < 10) { datestring += "0" + date.getFullYear(); } else { datestring+= date.getFullYear()}
        return datestring;
    },

    convertTimeStampToTime(tstamp:number){
        if (tstamp) {
            const dateFormat = new Date(tstamp * 1000);
            let hours : any = dateFormat.getHours();
            let minutes : any = dateFormat.getMinutes();

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            
            return hours + ":" + minutes
        } else {
            return '00:00'
        }
    },

    convertToDate(datetime:string) {
        if (datetime) {
            if (datetime.split('T')[0]) {
                datetime = datetime.split('T')[0]
            }
            const date = new Date(datetime)
            const m = date.getMonth()+1
            return (date.getDate() < 10 ? '0' : '') + date.getDate() + '.' + (m < 10 ? '0' : '') + m + '.' + date.getFullYear()
        } else {
            return '-'
        }
    },
};
export default Converter;