export default {
    de:{ 
        route:{
            administration: "Administration",
            timetracking: "Zeiterfassung",
            overview: "Übersicht",
            youDay: "Mein Tag",
            profile: "Mein Profil",
            projectlist: "Aufträge",
            login: "Login",
            addTimeentry: "Aktivität erstellen",
            editTimeentry: "Aktivität bearbeiten",
            dashboard: "Dashboard",
            employee: "Mitarbeiter",
            employeegroup: "Gruppen - Rechte",
            createEmployeegroup: "Gruppe erstellen",
            editEmployeegroup: "Gruppe bearbeiten",
            createEmployee: "Mitarbeiter erstellen",
            editEmployee: "Mitarbeiter bearbeiten",
            projects: "Aufträge",
            project: "Auftrag",
            createProject: "Auftrag erstellen",
            editProject: "Auftrag bearbeiten",
            works: "Tätigkeiten",
            work: "Tätigkeit",
            createWork: "Tätigkeit erstellen",
            editWork: "Tätigkeit bearbeiten",
            clients: "Kunden",
            client: "Kunde",
            createClient: "Kunde erstellen",
            editClient: "Kunde bearbeiten",
            departments: "Abteilungen",
            department: "Abteilung",
            createDepartment: "Abteilung erstellen",
            editDepartment: "Abteilung bearbeiten",
            machines: "Maschinen",
            machine: "Maschine",
            createMachine: "Maschine erstellen",
            editMachine: "Maschine bearbeiten",
            workstations: "Workstations",
            workstation: "Workstation",
            createWorkstation: "Workstation erstellen",
            editWorkstation: "Workstation bearbeiten",
            workareas: "Arbeitsbereiche",
            createWorkarea: "Arbeitsbereich erstellen",
            editWorkarea: "Arbeitsbereich bearbeiten",
            vehicles: "Fahrzeuge",
            personalization: "Personalisierung",
            workingTimeScheme: "Arbeitszeitmodelle",
            passwordForgotten: "Passwort vergessen",
            companySettings: "Einstellungen"
        },
        button:{
            logout: "Ausloggen",
            login: "Anmelden",
            ok: "OK",
            back: "zurück",
            edit: "Bearbeiten",
            deactivate: "Deaktivieren",
            delete: "Löschen",
            save: "Speichern",
            reset: "Zurücksetzen",
            create: "Erstellen",
            come: "Kommen",
            go: "Gehen",
            pause: "Pause",
            stop_pause: "Pause beenden",
            start: "Start",
            scan: "Scan",
            cancel: "Abbrechen",
            showAll: "Alle anzeigen",
            addEmployee: "Mitarbeiter anlegen",
            addProject: "Auftrag anlegen",
            addWork: "Aktivität anlegen",
            addClient: "Kunde erstellen",
            addVehicle: "Fahzeug hinzufügen",
            checkAll: "Alle auswählen",
            addDepartment: "Abteilung hinzufügen",
            createWorkarea: "Arbeitsbereich erstellen",
            closeEdit: "Bearbeitungsmodus schließen",
            addMachine: "Maschine hinzufügen",
            addWorkstation: "Workstation hinzufügen",
            addWorkarea: "Arbeitsbereich hinzufügen",
            addWorkingTimeScheme: "Arbeitszeitmodell erstellen",
            addEmployeegroup: "Gruppe anlegen",
            sendLink: "Link anfordern",
            backToLogin: "Zurück zum Login",
            resetPassword: "Bestätigen",
            next: "weiter",
            selectAll: "Alle auswählen",
            deSelectAll: "Auswahl aufheben",
            showDepartmentWorks: "Abteilung"
        },
        label:{
            title: "Titel",
            gender: "Geschlecht",
            firstname: "Vorname",
            lastname: "Nachname",
            email: "E-Mail",
            password: "Passwort",
            mailPrivate: "E-Mail (privat)",
            mailCompany: "E-Mail (Firma)",
            siNumber: "SV-Nummer",
            dateOfBirth: "Geburtsdatum",
            street: "Straße & Hausnummer",
            zip: "PLZ",
            city: "Stadt/Ort",
            telephone: "Telefon",
            company: "Firma",
            user: "Benutzer",
            employeeNumber: "Mitarbeiternummer",
            clientNumber: "Kundennummer",
            uploadProfileImage: "Profilbild hochladen",
            workplace: "Arbeitsplatz",
            address: "Adresse",
            version: "Version",
            usermode: "Benutzermodus",
            projectNumberExternal: "Externe Auftragsnummer",
            projectNumberInternal: "Interne Auftragsnummer",
            projectNumberSerial: "Laufende Auftragsnummer",
            projectName: "Name",
            budget: "Budget",
            budgetMoney: "Geldbudget",
            budgetTime: "Zeitbudget",
            plannedTurnover: "Planumsatz",
            client: "Kunde",
            selectProject: "Auftrag wählen",
            employee: "Mitarbeiter",
            employeeGroup: "Arbeitergruppe",
            startedAt: "Begonnen um",
            goneAt: "Gegangen um",
            selectWork: "Aktivität/Tägikeit wählen",
            hours:"Stunden",
            totalTime: "Gesamtzeit",
            oClock: "Uhr",
            hours_per_work: "Arbeitszeit nach Tätigkeit",
            project_hours: "Arbeitszeit",
            date: "Datum",
            work: "Tätigkeit",
            name: "Name",
            defaultHourlyRate: "Standardstundensatz",
            number: "Nummer",
            description: "Beschreibung",
            setupTime: "Vorbereitungszeit",
            active: "Aktiv",
            accounted: "Abgerechnet",
            no: "Nein",
            yes: "Ja",
            salesActual: "Ist-Umsatz",
            label: "Bezeichnung",
            workareas: "Arbeitsbereiche",
            department: "Abteilung",
            workarea: "Arbeitsbereich",
            hourlyRate: "Stundensatz",
            runtimeTarget: "Soll Laufzeit",
            machine: "Maschine",
            machines: "Maschinen",
            time: "Zeit",
            project: "Auftrag / Project",
            daysum: "Tagessumme",
            workbreakStart: "Begin",
            workbreakStop: "Ende",
            workbreakPaused: "Pause",
            workbreakNone: "keine Pause",
            allowLogin: "Direktes Login erlaubt",
            loginName: "Login",
            activateLogin: "Login aktivieren",
            employeegroup: "Gruppe",
            employeegroupTitle: "Bezeichnung",
            employeegroupSystemGroup: "Systemgruppe",
            employeegroupIsAdmin: "Admingruppe",
            employeegroupIsDefault: "Standardusergruppe",
            employeegroupRights: "Rechte",
            pw_forgotten: "Passwort vergessen?",
            new_password: "Neues Passwort",
            new_password_repeat: "Passwort wiederholen",
            pw_reset: "Passwort zurücksetzen",
            dashboardDay: "Tagesübersicht",
            defaultProject: "Standardauftrag",
            workInDepartments: "Verfügbar in Abteilung",
            general: "Allgemein"
        },
        placeholder:{
            search: "Suche",
            title: "Titel",
            gender: "Geschlecht wählen",
            firstname: "Vorname",
            lastname: "Nachname",
            mail: "E-Mail",
            siNumber: "1234",
            dateOfBirth: "DD:MM:YYYY",
            street: "Straße & Hausnummer",
            zip: "PLZ",
            city: "Stadt/Ort",
            telephone: "+43 ...",
            employeeNumber: "Mitarbeiternummer",
            uploadProfileImage: "Profilbild hochladen",
            workplace: "Arbeitsplatz wählen",
            address: "Adresse",
            projectNumberExternal: "Externe Auftragsnummer",
            projectNumberInternal: "Interne Auftragsnummer",
            projectNumberSerial: "Laufende Auftragsnummer",
            budgetMoney: "0 €",
            budgetTime: "00:00 h",
            plannedTurnover: "0 €",
            client: "Kunde",
            searchClient: "Kunde suchen/ erstellen",
            selectClient: "Kunde wählen",
            searchEmployee: "Mitarbeiter/Arbeitergruppe suchen",
            noDataFound: "Keine Daten gefunden.",
            password:"Passwort",
            selectProject: "Auftrag wählen",
            selectWork: "Suche und wähle eine Aktivität",
            description: "Beschreibung",
            number: "Number",
            numberExample: "123",
            selectDepartment: "Abteilung wählen",
            selectWorkarea: "Arbeitsbereich wählen",
            selectMachine: "Maschine wählen",
            loginWithEmail: "Email Adresse zum anmelden (Benutzername)",
            employeegroupTitle: "Bezeichnung",
            searchProject: "Auftrag suchen"
        },
        emptyState:{
            default: "Keine Daten",
            work: "Keine Aktivitäten vorhanden.",
            employeeStats: "Keine Informationen vorhanden.",
            works: "Keine Tätigkeiten zugewiesen.",
            projects: "Keine Aufträge gefunden.",
            workareas: "Keine Arbeitsbereiche gefunden.",
            employees: "Keine Mitarbeiter/innen gefunden.",
            clients: "Keine Kunden/-innen gefunden.",
            noActivitiesToday: "Keine Aktivitäten",
            activities: "Keine Aktivitäten gefunden.",
            noWorkareasAdded: "Kein Arbeitsbereich hinzugefügt.",
            employeegroups: "Keine Gruppen vorhanden",
            employeeDepartments: "Keinen Abteilungen zugeordnet"
        },
        success: {
            employeeCreated: "Mitarbeiter/in wurde erstellt.",
            employeeUpdated: "Mitarbeiter/in wurde aktualisiert.",
            employeeDeleted: "Mitarbeiter/in wurde gelöscht.",
            projectCreated: "Auftrag wurde erstellt.",
            projectUpdated: "Auftrag wurde aktualisiert.",
            projectDeleted: "Auftrag wurde gelöscht.",
            workstationCreated: "Workstation wurde erstellt.",
            workstationUpdated: "Workstation wurde aktualisiert.",
            workstationDeleted: "Workstation wurde gelöscht.",
            workCreated: "Tätigkeit wurde erstellt.",
            workUpdated: "Tätigkeit wurde aktualisiert.",
            workDeleted: "Tätigkeit wurde gelöscht.",
            workareaCreated: "Arbeitsbereich wurde erstellt.",
            workareaUpdated: "Arbeitsbereich wurde aktualisiert.",
            workareaDeleted: "Arbeitsbereich wurde gelöscht.",
            machineCreated: "Maschine wurde erstellt.",
            machineUpdated: "Maschine wurde aktualisiert.",
            machineDeleted: "Maschine wurde gelöscht.",
            clientCreated: "Kunde wurde erstellt.",
            clientUpdated: "Kunde wurde aktualisiert.",
            clientDeleted: "Kunde wurde gelöscht.",
            departmentCreated: "Abteilung wurde erstellt.",
            departmentUpdated: "Abteilung wurde aktualisiert.",
            departmentDeleted: "Abteilung wurde gelöscht.",
            employeegroupCreated: "Gruppe erfolgreich erstellt",
            employeegroupUpdated: "Gruppe erfolgreich aktualisiert",
            linkSent: "Wir haben dir an die angegebene EMail Adresse einen Link zum Zurücksetzen des Passwortes geschickt.",
            passwordChanged: "Dein Passwort wurde erfolgreich geändert.",
            companySettingsSaved: "Die Einstellungen wurden gespeichert"
        },
        error: {
            default: "Es ist ein Fehler aufgetreten.",
            missing: {
                title: "Der Titel muss eingegeben werden.",
                ID: "ID ungültig.",
                projectId: "Auftrag ID ungültig.",
                employeeId: "Mitarbeiter ID ungültig.",
                clientId: "Kunden ID ungültig.",
                employeetId: "Mitarbeiter ID ungültig.",
                firstnameOrLastname: "Name ist nicht vollständig.",
                custName: "Name/ Firma muss eingegeben werden."
            },
            notFound:{
                userOrCompany: "Benutzer oder Firma wurde nicht gefunden.",
                workarea: "Arbeisbereich wurde nicht gefunden.",
                work: "Tätigkeit wurde nicht gefunden.",
                workstation: "Workstation wurde nicht gefunden.",
                unit: "Maschine wurde nicht gefunden.",
                employee: "Mitarbeiter/in wurde nicht gefunden.",
                client: "Kunde wurde nicht gefunden.",
                department: "Abteilung wurde nicht gefunden.",

            },
            employee: {
                notfound: "Mitarbeiter/in wurde nicht gefunden."
            },
            timeentry: {
                edited: "Der Eintrag wurd bearbeitet"
            },
            notallowed: {
                feuser: "Email Adresse bereits als Login vergeben!"
            },
            required: "Erforderlich",
            wrong_mail: "Ungültige Email",
            pw_too_short: "Zu kurz",
            dont_match: "Keine Übereinstimmung",
            loginFailed: "Benutzer oder Passwort falsch!"
        },
        confirm: {
            delete: "Löschen bestätigen",
            deleteProject: "",
            deleteWorkstationText: 'Möchtest du die Workstation "{name}" wirklich löschen?',
            deleteMachineText: 'Möchtest du die Maschine "{name}" wirklich löschen?',
            deleteDepartmentText: 'Möchtest du die Abteilung "{name}" wirklich löschen?',
            deleteWorkText: 'Möchtest du die Tätigkeit "{name}" wirklich löschen?',
            deleteWorkareaText: 'Möchtest du den Arbeitsbereich "{name}" wirklich löschen?',
            deleteProjectText: 'Möchtest du den Auftrag "{name}" wirklich löschen?',
            deleteClientText: 'Möchtest du den Kunden/die Kundin "{name}" wirklich löschen?',
            deleteEmployeeText: 'Möchtest du den Mitarbeiter/die Mitarbeiterin "{name}" wirklich löschen?',
            deleteTimeEntryText: 'Möchtest du den Zeiteintrag wirklich löschen?',
        },
        dashboard: {
            title: "Dashboard",
            activities: "Aktivitäten / Arbeiten"
        },
        general: {
            number: "Nummer",
            numberShort: "Nr."
        },
        employee:{
            title: "Mitarbeiter", 
            number: "Mitarbeiter-Nr.",
            firstname: "Vorname",
            lastname: "Nachname",
            personalData: "Persönliche Daten",
            companyData: "Interne Mitarbeiterdaten",
            workplace: "Arbeitsplatz wählen",
            workingTimeInfo: "Informationen zur Arbeitszeit",
            lastWorkes: "Letzte Aktivitäten",
            absences: "Urlaub und externe Termine",
            selectTasks: "Tätigkeiten zuweisen",
            lastActivities: "Letzte Aktivitäten",
            departments: "Abteilungen",
            profile: "Profil"
        },
        employeegroup: {
            title: "Bezeichnung",
            systemGroup: "Systemgruppe",
            isAdmin: "Admingruppe",
            isDefault: "Standard",
            generalData: "Allgemeine Daten",
            rights: "Rechte"
        },
        project:{
            title: "Aufträge",
            projectData: "Allgemeine Auftragsdaten",
            client: "Kunde",
            addEmployee: "Mitarbeiter/ Arbeitergruppe",
            infos: "Auftraginfos",
            all: "Alle Aufträge",
            selectYourProject: "Auftrag",
            projectCreated: "Angelegt am",
            numberExternal: "Externe Auftrags-Nr.",
            numberInternal: "Interne Auftrags-Nr.",
            numberSerial: "Laufende Auftrags-Nr.",
            active: "Aktiv",
            workingHours: "Stunden",
            activeProjects: "Aktive Aufträge"
        },
        day: {
            dailyStatistic: "Tagesübersicht",
            activities: "Heutige Aktivitäten",
            presenceTime: "Anwesenheit",
            workingtimeSUM: "Arbeitsstunden gesamt",
            comeAt: "Begonnen um",
            goAt: "Gegangen um",
            lastInteractivity: "Letzte Interaktivität",
            workbreaks: "Pausen"
        },
        client:{
            title: "Kunden",
            number: "Nr.",
            name: "Name",
            active: "Active",
            generalData: "Allgemeine Infos",
            timeByActivities: "Zeit nach Aktivitäten",
            timeByProjects: "Zeit nach Aufträgen"
        },   
        timeTables:{
            title: "Stundenlisten"
        },
        work:{
            title: "Tätigkeiten",
            yourWorksToday: "Deine heutigen Aktivitäten",
            yourWorksFor: "Deine Aktivitäten für ",
            selectYourWork: "Wähle deine Tätigkeit",
            noCurrentActivity: "Keine aktive Tätikeit",
            name: "Bezeichnung",
            works: "Aktivitäten / Arbeiten"
        },
        vehicle:{
            title: "Fahrzeuge"
        },
        department:{
            number: "Nr.",
            title: "Bezeichnung"
        },
        machine: {
            number: "Nr.",
            title: "Bezeichnung"
        },
        workarea: {
            title: "Arbeitsbereich",
            selectSearchPlaceholder: "Arbeitsbereich wählen/eingeben",
            addNew: "Neuen Arbeitesbereich hinzufügen",
        },
        workstations:{
            title: "Workstations"
        },
        personalization:{
            viewPersonalization: "Ansichtspersonalisierung",
            viewPersonalizationText: "Passe deine Ansichten individuell an deine Bedürfnisse  an.",
            projectList: "Ansicht der Liste",
            projectListDescription: "Wähle die Felder aus, die in der Auftragliste angezeigt werden sollen",
            projectDetails: "Ansicht der Auftragdetails",
            general: "Allgemein",
        },
        companySettings: {
            viewCompanySettings: "Firmenweite Einstellungen",
            projects: "Aufträge"
        }
    }
}