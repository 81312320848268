import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "data-buttons"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["data-value", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ww-option-buttons", _ctx.alignmentAndSize]),
    id: _ctx.cId,
    "data-buttons": _ctx.options.length
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, idx) => {
      return (_openBlock(), _createElementBlock("button", {
        key: _ctx.cId + '_' +idx,
        class: _normalizeClass(["ww-custom-option-button", {'is-checked': _ctx.value == option.value}]),
        "data-value": option.value,
        onClick: ($event: any) => (_ctx.handleChange(option))
      }, _toDisplayString(option.label), 11, _hoisted_3))
    }), 128))
  ], 10, _hoisted_1))
}