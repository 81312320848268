
import { defineComponent } from 'vue';
import store from '@/store/index';
import { IconButton } from '@/ui/index';

export default defineComponent({
    name: "CollapseListItem",
    components: { IconButton },
    props:{
        title:{
            type: String, 
            default: ""
        },
        subtitle:{
            type: String, 
            default: ""
        },
    },
    data(){
        return{
            store, 
            active: false
        }
    },
    methods:{
        handleToggleActive(){
            this.active = !this.active
        }
    }
})
