import { DepartmentRepository } from "../repository";

const DepartmentController = {
    async fetchDepartments() {
        const res : any = await DepartmentRepository.fetchDepartments();
        return res
    },
    
    async getDepartmentById(uid:any){
        const res : any = await DepartmentRepository.getDepartmentById(uid)
        return res;
    },
    async createDepartment(formData : any) {
        const postData = `number=${formData.number}&title=${formData.title}`
        
        const res : any = await DepartmentRepository.createDepartment(postData)
        return res;
    },
    async updateDepartment(formData : any) {
        const postData = `uid=${formData.uid}&title=${formData.title}&number=${formData.number}`;
        const res : any = await DepartmentRepository.updateDepartment(postData);
        return res;
    },
    async deleteDepartment(departmentId : number) {
        const postData = `uid=${departmentId}`;
        const res : any = await DepartmentRepository.deleteDepartment(postData);
        return res
    }
}
export default DepartmentController;

