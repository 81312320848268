import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/overview",
  },
  {
    path: "/administration/dashboard",
    redirect: "/",
    name: "Dashboard",
    children: [
      {
        path: "/administration/dashboard",
        component: () => import("@/views/a-dashboard/index.vue"),
        name: "Dashboard",
        meta: {
          noCache: true
        }
      },
      {
        path: "/administration/dashboard/employee/:id",
        component: () => import("@/views/a-dashboard/employee/index.vue"),
        name: "DashboardEmployee",
        meta: {
          noCache: true
        }
      },
    ]
  },
  {
    path: "/administration/employees",
    redirect: "/",
    name: "EmployeeList",
    children: [
      {
        path: "/administration/employees",
        component: () => import("@/views/administration/employee/index.vue"),
        name: "EmployeeList",
        meta: {
          noCache: true
        }
      },
    ]
  },
  {
    path: "/administration/employee/form",
    redirect: "/",
    name: "EmployeeForm",
    children: [
      {
        path: "/administration/employee/form/:id",
        component: () => import("@/views/administration/employee/form.vue"),
        name: "EmployeeForm",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/employee/detail",
    redirect: "/",
    name: "EmployeeDetail",
    children: [
      {
        path: "/administration/employee/detail/:id",
        component: () => import("@/views/administration/employee/detail.vue"),
        name: "EmployeeDetail",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/employeegroups",
    redirect: "/",
    name: "EmployeegroupList",
    children: [
      {
        path: "/administration/employeegroups",
        component: () => import("@/views/administration/employeegroup/index.vue"),
        name: "EmployeegroupList",
        meta: {
          noCache: true
        }
      },
    ]
  },  
  {
    path: "/administration/employeegroup/detail",
    redirect: "/",
    name: "EmployeegroupDetail",
    children: [
      {
        path: "/administration/employeegroup/detail/:id",
        component: () => import("@/views/administration/employeegroup/detail.vue"),
        name: "EmployeegroupDetail",
        meta: {
          noCache: true
        }
      }
    ]
  },  
  {
    path: "/administration/employeegroup/form",
    redirect: "/",
    name: "EmployeegroupForm",
    children: [
      {
        path: "/administration/employeegroup/form/:id",
        component: () => import("@/views/administration/employeegroup/form.vue"),
        name: "EmployeegroupForm",
        meta: {
          noCache: true
        }
      }
    ]
  },  
  {
    path: "/administration/projects",
    redirect: "/",
    name: "ProjectList",
    children: [
      {
        path: "/administration/projects",
        component: () => import("@/views/a-project/index.vue"),
        name: "ProjectList",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/project/form/",
    redirect: "/",
    name: "ProjectForm",
    children: [
      {
        path: "/administration/project/form/:id",
        component: () => import("@/views/a-project/form.vue"),
        name: "ProjectForm",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/project/detail",
    redirect: "/",
    name: "ProjectDetail",
    children: [
      {
        path: "/administration/project/detail/:id",
        component: () => import("@/views/a-project/detail.vue"),
        name: "ProjectDetail",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/clients",
    redirect: "/",
    name: "ClientList",
    children: [
      {
        path: "/administration/clients",
        component: () => import("@/views/a-client/index.vue"),
        name: "ClientList",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/client/form/",
    redirect: "/",
    name: "ClientForm",
    children: [
      {
        path: "/administration/client/form/:id",
        component: () => import("@/views/a-client/form.vue"),
        name: "ClientForm",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/client/detail/",
    redirect: "/",
    name: "ClientDetail",
    children: [
      {
        path: "/administration/client/detail/:id",
        component: () => import("@/views/a-client/detail.vue"),
        name: "ClientDetail",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/works",
    redirect: "/",
    name: "WorkList",
    children: [
      {
        path: "/administration/works",
        component: () => import("@/views/administration/work/index.vue"),
        name: "WorkList",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/work/form/",
    redirect: "/",
    name: "WorkForm",
    children: [
      {
        path: "/administration/work/form/:id",
        component: () => import("@/views/administration/work/form.vue"),
        name: "WorkForm",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/departments",
    redirect: "/",
    name: "DepartmentList",
    children: [
      {
        path: "/administration/departments",
        component: () => import("@/views/a-department/index.vue"),
        name: "DepartmentList",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/department/form/",
    redirect: "/",
    name: "DepartmentForm",
    children: [
      {
        path: "/administration/department/form/:id",
        component: () => import("@/views/a-department/form.vue"),
        name: "DepartmentForm",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/department/detail/",
    redirect: "/",
    name: "DepartmentDetail",
    children: [
      {
        path: "/administration/department/detail/:id",
        component: () => import("@/views/a-department/detail.vue"),
        name: "DepartmentDetail",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/machines",
    redirect: "/",
    name: "MachineList",
    children: [
      {
        path: "/administration/machines",
        component: () => import("@/views/a-machine/index.vue"),
        name: "MachineList",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/machine/form/",
    redirect: "/",
    name: "MachineForm",
    children: [
      {
        path: "/administration/machine/form/:id",
        component: () => import("@/views/a-machine/form.vue"),
        name: "MachineForm",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/machine/detail/",
    redirect: "/",
    name: "MachineDetail",
    children: [
      {
        path: "/administration/machine/detail/:id",
        component: () => import("@/views/a-machine/detail.vue"),
        name: "MachineDetail",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/workstations",
    redirect: "/",
    name: "WorkstationList",
    children: [
      {
        path: "/administration/workstations",
        component: () => import("@/views/a-workstation/index.vue"),
        name: "WorkstationList",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/workstation/form/",
    redirect: "/",
    name: "WorkstationForm",
    children: [
      {
        path: "/administration/workstation/form/:id",
        component: () => import("@/views/a-workstation/form.vue"),
        name: "WorkstationForm",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/workareas",
    redirect: "/",
    name: "WorkareaList",
    children: [
      {
        path: "/administration/workareas",
        component: () => import("@/views/a-workarea/index.vue"),
        name: "WorkareaList",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/workarea/form/",
    redirect: "/",
    name: "WorkareaForm",
    children: [
      {
        path: "/administration/workarea/form/:id",
        component: () => import("@/views/a-workarea/form.vue"),
        name: "WorkareaForm",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/vehicles",
    redirect: "/",
    name: "VehicleList",
    children: [
      {
        path: "/administration/vehicles",
        component: () => import("@/views/a-vehicles/index.vue"),
        name: "VehicleList",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/workingtimeschemes",
    redirect: "/",
    name: "WorkingTimeSchemeList",
    children: [
      {
        path: "/administration/workingtimeschemes",
        component: () => import("@/views/a-workingtimescheme/index.vue"),
        name: "WorkingTimeSchemeList",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/administration/companysettings",
    redirect: "/",
    name: "CompanySettings",
    children: [
      {
        path: "/administration/companysettings",
        component: () => import("@/views/administration/companysettings/index.vue"),
        name: "CompanySettings",
        meta: {
          noCache: true
        }
      }
    ]
  },  
  {
    path: "/administration/personalization",
    redirect: "/",
    name: "Personalization",
    children: [
      {
        path: "/administration/personalization",
        component: () => import("@/views/a-personalization/index.vue"),
        name: "Personalization",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/overview",
    redirect: "/",
    name: "Overview",
    children: [
      {
        path: "/overview",
        component: () => import("@/views/overview/index.vue"),
        name: "Overview",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/projects",
    redirect: "/",
    name: "Projects",
    children: [
      {
        path: "/projects",
        component: () => import("@/views/project/index.vue"),
        name: "Projects",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/project/edit/:id",
    redirect: "/",
    name: "ProjectFrontendForm",
    children: [
      {
        path: "/project/edit/:id",
        component: () => import("@/views/project/form.vue"),
        name: "ProjectFrontendForm",
        meta: {
          noCache: true
        }
      }
    ]
  },  
  {
    path: "/profile",
    redirect: "/",
    name: "Profile",
    children: [
      {
        path: "/profile",
        component: () => import("@/views/profile/index.vue"),
        name: "Profile",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/dailyreport",
    redirect: "/",
    name: "DailyReport",
    children: [
      {
        path: "/dailyreport",
        component: () => import("@/views/dailyreport/index.vue"),
        name: "DailyReport",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/projectdetail",
    redirect: "/",
    name: "UserProjectDetail",
    children: [
      {
        path: "/projectdetail/:id",
        component: () => import("@/views/projectdetail/index.vue"),
        name: "UserProjectDetail",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/login",
    redirect: "/",
    name: "Login",
    children: [
      {
        path: "/login/",
        component: () => import("@/views/login/index.vue"),
        name: "Login",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/forgotpassword",
    redirect: "/",
    name: "ForgotPassword",
    children: [
      {
        path: "/forgotpassword",
        component: () => import("@/views/forgotpassword/index.vue"),
        name: "ForgotPassword",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/resetpassword",
    redirect: "/",
    name: "ResetPassword",
    children: [
      {
        path: "/resetpassword/:hash",
        component: () => import("@/views/resetpassword/index.vue"),
        name: "ResetPassword",
        meta: {
          noCache: true
        }
      }
    ]
  },    
  {
    path: "/addtimeentry/",
    redirect: "/",
    name: "AddTimeEntry",
    children: [
      {
        path: "/addtimeentry/",
        component: () => import("@/views/timeentry/add.vue"),
        name: "AddTimeEntry",
        meta: {
          noCache: true
        }
      }
    ]
  },
  {
    path: "/edittimeentry/:id",
    redirect: "/",
    name: "EditTimeEntry",
    children: [
      {
        path: "/edittimeentry/:id",
        component: () => import("@/views/timeentry/edit.vue"),
        name: "EditTimeEntry",
        meta: {
          noCache: true
        }
      }
    ]
  },  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
