
import { defineComponent } from 'vue';

export default defineComponent({
    name: "CollapseList",
    props:{
        title:{
            type: String, 
            default: ""
        },
        subtitle:{
            type: String, 
            default: ""
        },
    }
})
