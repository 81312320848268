import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ww-list-item", {'interactive': _ctx.interactive, 'swipe-left': _ctx.swipeLeft, 'swipe-right': _ctx.swipeRight }]),
    ref: "wwListItem",
    onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseDown && _ctx.handleMouseDown(...args))),
    onMousemove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseMove && _ctx.handleMouseMove(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args))),
    onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleMouseUp && _ctx.handleMouseUp(...args))),
    onTouchstart: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleTouchStart && _ctx.handleTouchStart(...args))),
    onTouchmove: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleTouchMove && _ctx.handleTouchMove(...args))),
    onTouchend: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 34))
}