
import { defineComponent } from 'vue';

export default defineComponent({
    name: "DataList",
    props:{
        title:{
            type: String, 
            default: ""
        }
    }
});
