//import { DataService } from '../utils';
import { PresenceRepository } from '@/repository/index.ts';

const PresenceController = {
    async getPresences(start: number, end: number, employeeId: number) {
        const postData = `start=${start}&end=${end}&employee=${employeeId}`
        const res : any = await PresenceRepository.getPresences(postData)
        return res
    },
    async getPresence(uid:any){
        const res : any = await PresenceRepository.getPresence(uid)
        return res
    },
    async getActivePresence(employeeId:any){
        const res : any = await PresenceRepository.getActivePresence(employeeId)
        return res
    },
    async createPresence(employee:any){
        const postData = `employeeId=${employee.getUid()}`
        const res : any = await PresenceRepository.createPresence(postData)
        return res
    },
    async updatePresence(employee:any){
        const postData = `employeeId=${employee.getUid()}`
        const res : any = await PresenceRepository.updatePresence(postData)
        return res
    },
 }
export default PresenceController;

