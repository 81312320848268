export default class Machine {
    uid: number;
    number: string;
    title: string;
    department: any;
    workarea: any;
    hourlyRate: number;
    runtimeTarget: number;
    
    constructor(uid: number, number: string, title: string, department: number, workarea: any, hourlyRate: number, runtimeTarget : number ) {
        this.uid = uid;
        this.number = number;
        this.title = title;
        this.department = department;
        this.workarea = workarea;
        this.hourlyRate = hourlyRate;
        this.runtimeTarget = runtimeTarget;
    }

    static createMachinesFromResponse(res:any) {
        const machines :any = [];
        res.data.units.forEach((element: any) => {
            machines.push(new Machine (element.uid, element.number, element.title, element.department, element.workarea, element.hourlyRate, element.runtimeTarget)
        )})
        return machines;
    }

    static createMachineFromResponse(res:any) {
        return new Machine (res.uid, res.number, res.title, res.department, res.workarea, res.hourlyRate, res.runtimeTarget);
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getNumber() {
        return this.number;
    }

    setNumber(number:string) {
        this.number = number ;
    }  

    getTitle() {
        return this.title;
    }

    setTitle(title:string) {
        this.title = title;
    }  
    getDepartment() {
        return this.department;
    }

    setDepartment(department:number) {
        this.department = department;
    }

    getWorkarea() {
        return this.workarea;
    }

    setWorkarea(workarea : any) {
        this.workarea = workarea;
    }

    getHourlyRate() {
        return this.hourlyRate;
    }

    setHourlyYRate(hourlyRate : number) {
        this.hourlyRate = hourlyRate;
    }

    getRuntimeTarget() {
        return this.runtimeTarget;
    }

    setRuntimeTarget(runtimeTarget : number) {
        this.runtimeTarget = runtimeTarget;
    }  
}
    