import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "ww-button-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "ww-button-title" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.primary)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(["ww-button primary", {'raised': _ctx.raised, 'disabled': _ctx.disabled, 'loading': _ctx.waitForResponse }]),
        disabled: _ctx.waitForResponse || _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClicked && _ctx.handleClicked(...args)))
      }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1),
        (_ctx.waitForResponse)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: "svgicon svgicon--loader",
              innerHTML: _ctx.loader
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: _normalizeClass(["ww-button secondary", {'dense': _ctx.dense}]),
        disabled: _ctx.waitForResponse || _ctx.disabled,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClicked && _ctx.handleClicked(...args)))
      }, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.text), 1),
        (_ctx.waitForResponse)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: "svgicon svgicon--loader",
              innerHTML: _ctx.loader
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ], 10, _hoisted_4))
}