export default class CompanySettings {
    uid: number
    defaultProject: number
    
    constructor(uid: number, defaultProject: number) {
        this.uid = uid;
        this.defaultProject = defaultProject;
    }

    static createCompanySettingsFromResponse(res:any) {
        return new CompanySettings(res.uid, (res.defaultProject.uid ? res.defaultProject.uid : res.defaultProject));
    }


    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getDefaultProject() {
        return this.defaultProject;
    }

    setDefaultProject(defaultProject:number) {
        this.defaultProject = defaultProject;
    }  
}