import { ProjectRepository } from '@/repository/index.ts';

const ProjectController = {

    getProjects() {
        return ProjectRepository.projects
    },
    async fetchProjects(){
        const res : any = await ProjectRepository.fetchProjects()
        return res
    },
    
    async getProjectById(uid:any){
        const res : any = await ProjectRepository.getProjectById(uid)
        return res
    },

    async getProjectsByClient(uid:any) {
        const res : any = await ProjectRepository.getProjectsByClient(uid);
        return res;
    },

    async createProject(formData : any) {
        const postData = `numberExternal=${formData.numberExternal}&numberInternal=${formData.numberInternal}&numberSerial=${formData.numberSerial}&title=${formData.title}&customerId=${formData.customer.uid}&custName=${formData.customer.custName}&moneyBudget=${formData.moneyBudget}&timeBudget=${formData.timeBudget}&salesTarget${formData.salesTarget}`
        const res : any = await ProjectRepository.createProject(postData)
        return res
    },

    async updateProject(formData : any) {
        const postData = `projectId=${formData.uid}&numberExternal=${formData.numberExternal}&numberInternal=${formData.numberInternal}&numberSerial=${formData.numberSerial}&title=${formData.title}&customerId=${formData.customer.uid}&custName=${formData.customer.custName}&moneyBudget=${formData.moneyBudget}&timeBudget=${formData.timeBudget}&salesTarget${formData.salesTarget}`
        const res : any = await ProjectRepository.updateProject(postData)
        return res
    },

    async toggleProject(formData : any) {
        const postData = `projectId=${formData.uid}&status=${formData.status}`
        const res : any = await ProjectRepository.toggleProject(postData)
        return res
    },

    async deleteProject(projectId : number) {
        const postData = `uid=${projectId}`
        const res : any = await ProjectRepository.deleteProject(postData);
        return res
    }
}
export default ProjectController;

