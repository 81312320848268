//import { DataService } from '../utils';
import { ClientRepository } from '@/repository/index.ts';

const ClientController = {

    getClients() {
        return ClientRepository.clients
    },
    async fetchClients(){
        const res : any = await ClientRepository.fetchClients()
        return res;
    },
    async getClientById(uid:any){
        const res : any = await ClientRepository.getClientById(uid)
        return res;
    },
    async createClient(formData:any){
        const postData = `custName=${formData.custName}&number=${formData.number}`;
        const res : any = await ClientRepository.createClient(postData)
        return res;
    },
    async updateClient(formData : any) {
        const postData = `uid=${formData.uid}&custName=${formData.custName}&number=${formData.number}&active=${formData.active}`; 
        const res : any = await ClientRepository.updateClient(postData);
        return res;
    },
    async toggleClient(formData : any) {
        const postData = `customerId=${formData.uid}&status=${formData.status}`
        const res : any = await ClientRepository.toggleClient(postData)
        return res
    },
    async deleteClient(clientId : number) {
        const postData = `uid=${clientId}`;
        const res : any = await ClientRepository.deleteClient(postData);
        return res;
    }
}

export default ClientController;

