
import store from '@/store/index';
import { Button, IconButton } from '@/ui/index';
import { defineComponent, onMounted, ref, computed, watch} from 'vue';
import { Unique } from '@/utils';

export default defineComponent({
  name: "SelectSingleSearch",
  components: { Button, IconButton },
  props: {
      item: {
        type: Object,
        default: null
      },
      options: {
        type: Array,
        default: null
      },
      value: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      primary: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ""
      },
      allowCreateNew: {
        type: Boolean,
        default: false
      },
      subheader: {
        type: Array,
        default: null
      },
      labelProperty: {
        type: String,
        default: ""
      },
      emptyStateText: {
        type: String,
        default: ""
      },
      secondary: {
        type: Boolean,
        default: false
      },
  },
  data() {
    return {
      store
    }
  },
  setup(props, { emit }) {
    const domSelected = ref<HTMLParagraphElement|null>(null);
    const cId = Unique.getUnique()
    const modelValue = ref(0)
    const inputValue = ref("")
    const undoInputValue = ref("")
    const filteredOptions = ref([] as Array<any>)
    const listActive = ref(false);
    const enableAddButton = ref(false);
    const selectedValue = ref({
      text: "",
      value: 0
    });

    const bodyClickHandler = ref()

    const selectListStyle = computed(() => {
      let style = "";
      if (domSelected.value) {
        const br = domSelected?.value?.getBoundingClientRect();
        style = listActive.value ? "top: " + Number(br.height) +  "px; width: " + (br.width - 2) + "px;" : "";
      }
      return style;
    })

    const filterOptions = () => {
        let filteredList : Array<any> = props.options;     
        if (inputValue.value) {
          filteredList = filteredList.filter((item:any)=>{
            if((inputValue.value && item[props.labelProperty].toLowerCase().includes(inputValue.value.toLowerCase()) || !inputValue.value)) {
              return item
            } 
          })
        }
        if (inputValue.value != "" && listActive.value == false) {
          handleToggleList();
          if (filteredList.length == 1 && inputValue.value == filteredList[0][props.labelProperty]) {
            handleHideList();
          }
        }
        filteredOptions.value = filteredList
    }

    const handleInputKeyDown = (ev: KeyboardEvent) => {
      if (ev.key == 'Escape') {
        inputValue.value = undoInputValue.value
        listActive.value = false
      }
    }

    const handleChangeInput = () => {
      filterOptions()

      if (listActive.value && (filteredOptions.value && !filteredOptions.value.some(el => el[props.labelProperty] == inputValue.value))) {
        enableAddButton.value = true;
      } else {
        enableAddButton.value = false;
      }
      emit("onChange", props.item, modelValue.value, inputValue.value);
    }   

    onMounted(() => {
      filterOptions()
    })

    const handleHideList = () => {
      listActive.value = false;
    }

    const isSelectedItem = (uid:any) => {
      if(uid == selectedValue.value.value) {
        return true;
      } 
      return false;
    }

    bodyClickHandler.value = (ev:Event) => {
      const composed = ev.composedPath();
      if (composed) {
        const close = composed.filter((el: any) => el.classList && el.classList.contains('input-action-bar') && el?.id == 'selectSingleSelectList'+cId);
      
        if (!close.length) {
          listActive.value = false;
        }
      }
    }

    const handleToggleList = () => {
      listActive.value = !listActive.value;
      document.body.addEventListener("click", bodyClickHandler.value);          
    }

    const handleSelectListClick = (ev : Event) => {
      const target = ev?.target as HTMLElement;

      if (target.nodeName == "LI") {
        selectedValue.value.value = Number(target.dataset['value'])
        modelValue.value = Number(target.dataset['value']);
        inputValue.value = target.innerHTML.trim();
          //TODO: check usability
        //undoInputValue.value = target.innerHTML.trim();
        selectedValue.value.text = target.innerHTML.trim();
        listActive.value = false;
        emit("onChange", props.item, modelValue.value, selectedValue.value.text);
      }
      handleHideList();
    }

    const handleAddItemClicked = (ev : Event) => {
      handleHideList()
      emit("onAddItemClicked", inputValue.value)
      inputValue.value = ""
    }

    watch(() => props.value, (oldValue, newValue) => {
      //console.log('watch props.storedValue, oldValue, newValue', props.value)
      modelValue.value = props.value.value
      selectedValue.value.text = props.value.text
      inputValue.value = props.value.text
      undoInputValue.value = (!undoInputValue.value ? props.value.text : undoInputValue.value)
      //listActive.value = false;
    })

    watch(() => listActive.value, (newValue, oldValue) => {
      if (!newValue) document.body.removeEventListener('click', bodyClickHandler.value)
    })

    watch(() => props.options, () => {
      filterOptions()
    })

    return {
      domSelected,
      cId,
      modelValue,
      inputValue,
      listActive,
      selectedValue,
      selectListStyle,
      filteredOptions,
      enableAddButton,
      isSelectedItem,
      handleToggleList,
      handleHideList,
      handleSelectListClick,
      handleAddItemClicked,
      handleChangeInput,
      handleInputKeyDown
    }
  },
})
