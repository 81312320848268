import { CompanyRepository } from "../repository";
//import { DataService } from '@/service';
import { Company } from "@/model/index.ts";

const CompanyController = {
    async getCompany(){
        const res : any = await CompanyRepository.getCompany();
        return res
    },

    async postSettings(formData: any, uid: number) {
        if (uid) formData['uid'] = uid
        const res : any = await CompanyRepository.postSettings(JSON.stringify(formData));
        return res
    },

}
export default CompanyController;

