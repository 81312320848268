
import { defineComponent } from 'vue';
import { Unique } from "../../utils";

export default defineComponent({
  name: "Switch",
  props: {
      handles: {
        type: Array,
        default: null
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      value: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      mini: {
        type: Boolean,
        default: false
      },
  },
  data() {
    return {
      formValue: this.value,
      elName: { type: String, default: Unique.getUnique()}
    }
  },
  watch: {
    value(){
      this.formValue = this.value
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.formValue = !this.formValue;
        this.$emit("onChange", this.formValue);
      }
    },
    onChange() {
      if (!this.disabled) {
        this.formValue = !this.formValue;
        this.$emit("onChange", this.formValue);
      }
    },
    onTransitionend() {
        //TODO: check if works in every case
      if (!this.disabled) this.$emit("onChange", this.name, this.formValue);
    }
  },
});
