import { MachineRepository } from "@/repository";

const MachineController = {
    async fetchMachines() {
        const res : any = await MachineRepository.fetchMachines();
        return res
    },
    async getMachineById(machineId:any){
        const res : any = await MachineRepository.getMachineById(machineId)
        return res;
    },
    async createMachine(formData : any) {
        const postData = `number=${formData.number}&title=${formData.title}&department=${formData.department.uid}&workarea=${formData.workarea.uid}`;
        const res : any = await MachineRepository.createMachine(postData)
        return res;
    },
    async updateMachine(formData : any) {
        const postData = `uid=${formData.uid}&department=${formData.department.uid}&title=${formData.title}&number=${formData.number}&hourlyRate=${formData.hourlyRate}&runtimeTarget=${formData.runtimeTarget}`;
        const res : any = await MachineRepository.updateMachine(postData);
        return res;
    },
    async deleteMachine(uid : number) {
        const postData = `uid=${uid}`;
        const res : any = await MachineRepository.deleteMachine(postData);
        return res
    }
}
export default MachineController;

