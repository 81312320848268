
import { defineComponent } from 'vue';

export default defineComponent({
    name: "ListItem",
    props: {
        interactive:{
            type: Boolean,
            default: false
        },
        swipeLeft: {
            type: Boolean,
            default: false,
        },
        swipeRight: {
            type: Boolean,
            default: false
        },
        resetSwipe: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            swipe: {
                startX: 0,
                mouseX: 0,
                offsetX: 0,
                component: {} as HTMLElement,
                revealLeft: {} as HTMLElement,
                revealRight: {} as HTMLElement,
                currentX: 0,
                maxLeft: 0,
                maxRight: 0,
                active: false
            },
            fullySwiped: false
        }
    },
    watch: {
        resetSwipe: function() {
            if (this.resetSwipe && this.fullySwiped) {
                this.swipe.currentX = 0
                this.fullySwiped = false;
                (this.swipe.component as HTMLElement).classList.add('has-transition');
                this.$nextTick(() => (this.swipe.component as HTMLElement).style.transform = 'translate(' + (this.swipe.currentX) + 'px, 0)' )                
            }
        }
        ,
    },  
    mounted() {
        if (this.swipeLeft || this.swipeRight) {
            const c = (this.$refs.wwListItem as HTMLElement).querySelector('.swipe--component')
            if (c) this.swipe.component = c as HTMLElement
            if (this.swipe.component) {
                this.swipe.component.addEventListener('transitionend', () => {
                    this.swipe.component.classList.remove('has-transition')
                })
            }
        }
        if (this.swipeLeft) {
            const c = (this.$refs.wwListItem as HTMLElement).querySelector('.swipe--left')
            if (c) this.swipe.revealLeft = c as HTMLElement
        }
        if (this.swipeRight) {
            const c = (this.$refs.wwListItem as HTMLElement).querySelector('.swipe--right')
            if (c) this.swipe.revealRight = c as HTMLElement
        }
    },
    methods:{
        handleMouseDown(ev: MouseEvent) {
            if (this.readOnly) return
            if (this.swipeLeft || this.swipeRight) {
                if (ev.buttons == 1) {
                    this.checkSwipeStart(ev.clientX)
                }
            }
        },
        handleTouchStart(ev: TouchEvent) {
            if (this.readOnly) return
            if (this.swipeLeft || this.swipeRight) {
                this.checkSwipeStart(ev.touches[0].clientX)
            }
        },
        handleMouseMove(ev: MouseEvent) {
            if (this.readOnly) return
            if (this.swipeLeft || this.swipeRight) {
                if (ev.buttons == 1) {
                    this.runSwipe(ev.clientX)
                }
            }
        },
        handleTouchMove(ev: TouchEvent) {
            if (this.readOnly) return
            if (this.swipeLeft || this.swipeRight) {
                this.runSwipe(ev.touches[0].clientX)
            }
        },
        handleMouseLeave() {
            if (this.readOnly) return
            if (this.swipe.active) this.checkSwipeCondition()
        },
        handleMouseUp() {
            if (this.readOnly) return
            if (this.swipe.active) this.checkSwipeCondition()
        },
        handleTouchEnd() {
            if (this.readOnly) return
            if (this.swipe.active) this.checkSwipeCondition()
        },
        checkSwipeStart(x: number) {
            if (this.$refs.wwListItem) {
                this.swipe.active = true
                this.swipe.offsetX = (this.$refs.wwListItem as HTMLElement).getBoundingClientRect().x;
                this.swipe.mouseX = (x - this.swipe.offsetX)
                if (this.swipe.revealLeft) this.swipe.maxLeft = this.swipe.revealLeft.getBoundingClientRect().width
                if (this.swipe.revealRight) this.swipe.maxRight = this.swipe.revealRight.getBoundingClientRect().width*-1
            }
        },
        runSwipe(x: number) {
            if (((this.swipeRight || this.swipeLeft) && this.swipe.component)) {
                const delta = (x - this.swipe.offsetX) - this.swipe.mouseX
                this.swipe.currentX = (this.swipe.currentX + delta) > this.swipe.maxRight ? ((this.swipe.currentX + delta) < this.swipe.maxLeft ? (this.swipe.currentX + delta) : this.swipe.maxLeft) : this.swipe.maxRight;
                (this.swipe.component as HTMLElement).style.transform = 'translate(' + (this.swipe.currentX) + 'px, 0)'
                this.swipe.mouseX = this.swipe.mouseX + delta
                if (this.swipe.currentX >= this.swipe.maxLeft || this.swipe.currentX <= this.swipe.maxRight) {
                    this.checkSwipeCondition()
                }
            }

        },
            //check Reset
        checkSwipeCondition() {
            if (this.swipe.active) {
                let repos = false
                if (this.swipe.currentX <= (this.swipe.maxRight + 10)) {
                    this.fullySwiped = true
                    repos = true
                    this.$emit('onSwipeRight')
                    this.swipe.currentX = this.swipe.maxRight
                } else if (this.swipe.currentX < this.swipe.maxLeft -10) {
                    this.swipe.currentX = 0;
                    repos = true
                } else if (this.swipe.currentX >= (this.swipe.maxLeft -10)) {
                    this.fullySwiped = true
                    this.swipe.currentX = this.swipe.maxLeft;
                    this.$emit('onSwipeLeft')
                    repos = true
                } 
                if (repos) {
                    this.swipe.active = false;
                    (this.swipe.component as HTMLElement).classList.add('has-transition');
                    this.$nextTick(() => (this.swipe.component as HTMLElement).style.transform = 'translate(' + (this.swipe.currentX) + 'px, 0)' )
                }
            }
        }

    }
})
