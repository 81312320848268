export default class Right {
    uid: number;
    title: string;
    identifier: string;
    context: number;

    constructor(
        uid : number, title:string, identifier: string, context: number
    ){
        this.uid = uid
        this.title = title
        this.identifier = identifier
        this.context = context
    }

    static createRightsFromResponse(res:any) {
        const rights :any = [];
        res.data.rights.forEach((element: any) => {
            rights.push(new Right(element?.uid, element?.title, element?.identifier, element?.context)
        )})
        console.log('rights', rights);
        return rights
    }

    static createRightFromResponse(res:any) {
        return new Right(
            res?.uid, res?.title, res?.identifier, res?.context
        );
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid
    }
 
     setUid(uid:number) {
         this.uid = uid
     }  

     getTitle() {
        return this.title
     }
 
     setTitle(title: string) {
         this.title = title
     } 

     getIdentifier() {
        return this.identifier
     }
 
     setIdentifier(identifier:string) {
         this.identifier = identifier
     }        

     getContext() {
        return this.context
     }
 
     setContext(context:number) {
         this.context = context
     }             

}