//import { DataService } from '../utils';
import { WorkRepository } from '@/repository/index.ts';

const WorkController = {
    async fetchWorks() {
        const works : any = await WorkRepository.fetchWorks();
        return works;
    },
    async getWorkById(uid:any){
        const res : any = await WorkRepository.getWorkById(uid);
        return res;
    },
    async createWork(workData : any){
        const departments = JSON.stringify(workData.departments)
        const postData = `number=${workData.number ? workData.number : 0 }&title=${workData.title}&defaultHourlyRate=${workData.defaultHourlyRate}&setupTime=${workData.setupTime}&description=${workData.description}}&departments=${departments}`;
        const response : any = await WorkRepository.createWork(postData);
        return response;
    },
    async updateWork(workData : any){
        const departments = JSON.stringify(workData.departments)
        const postData = `workId=${workData.uid}&number=${workData.number ? workData.number : 0 }&title=${workData.title}&defaultHourlyRate=${workData.defaultHourlyRate}&setupTime=${workData.setupTime}&description=${workData.description}&departments=${departments}`;
        const response : any = await WorkRepository.updateWork(postData);
        return response;
    },
    async deleteWork(workId : number) {
        const postData = `uid=${workId}`;
        const res : any = await WorkRepository.deleteWork(postData);
        return res;
    }
}
export default WorkController;

