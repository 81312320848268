
import { defineComponent } from "vue"
import store from "@/store/index";
import {Button, Checkbox } from "@/ui";

export default defineComponent({
   name: "CheckboxMultipleSelect",
   components: { Button, Checkbox},
   props: {
        options:{
            type: Array, 
            default: null
        },
        value: {
            type: Array,
            default: null
        },
        propertyName: {
            type: String,
            default: ''
        }
    },
   data() {
       return{
           store,
           checkedArray: [] as Array<any>
       }
   },
    watch:{
        value() {
            if (this.value) {
                this.checkedArray = this.value
            }
        }
   },
   created() {
        this.checkedArray = this.value
   },
   methods: {
        checkIsChecked(checkItem:any) {
            if (checkItem) {
                if (this.propertyName) {
                    if (this.checkedArray && this.checkedArray.length && this.checkedArray.includes(checkItem[this.propertyName])) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (this.checkedArray && this.checkedArray.length && this.checkedArray.includes(checkItem)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            } else {
                return false
            }
        },
        handleCheckAll() {
            this.checkedArray = [];
            this.options.forEach((item:any) => {
                this.checkedArray.push(item)
            });
        },
        handleUnCheckAll() {
            this.checkedArray = [];
        },
        handleUpdateArray(item:any, value:boolean) {
            if (value) {
                this.checkedArray.push(item)
            } else {
                this.checkedArray = this.checkedArray.filter((checkedItem : any) => {
                    return item.uid != checkedItem.uid
                })
            }
            this.$emit("onChange", this.checkedArray)
        },
    }
})
