import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "ww-search-list" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "sub-header"
}
const _hoisted_4 = ["data-value"]
const _hoisted_5 = {
  key: 1,
  class: "sub-header"
}
const _hoisted_6 = ["data-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["search-list", { 'is-disabled': _ctx.disabled, 'primary': _ctx.primary}]),
      ref: "domCustomSelect"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["input-action-bar", { 'list-is-active': _ctx.listActive }])
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: _ctx.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
        }, null, 8, _hoisted_2), [
          [_vModelText, _ctx.inputValue]
        ]),
        _createVNode(_component_IconButton, {
          icon: _ctx.store.getters.svgIcons.arrowdown,
          onOnClick: _ctx.handleToggleList
        }, null, 8, ["icon", "onOnClick"]),
        (_ctx.allowCreateNew)
          ? (_openBlock(), _createBlock(_component_IconButton, {
              key: 0,
              icon: _ctx.store.getters.svgIcons.add,
              onOnClick: _ctx.handleAddItemClicked
            }, null, 8, ["icon", "onOnClick"]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.filteredOptions.length > 0 || _ctx.filteredSecondOptionGroup.length > 0)
        ? (_openBlock(), _createElementBlock("ul", {
            key: 0,
            class: _normalizeClass(["selectlist", { 'is-active': _ctx.listActive }]),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSelectListClick && _ctx.handleSelectListClick(...args))),
            style: _normalizeStyle(_ctx.selectListStyle)
          }, [
            (_ctx.subheader && _ctx.secondOptionGroup != null && _ctx.filteredOptions.length >0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.subheader[0].text), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: 'optionItem' + _ctx.item.name + index,
                "data-value": option.uid,
                class: _normalizeClass({'isSelected': _ctx.isSelectedItem(option.uid)})
              }, _toDisplayString(option.text), 11, _hoisted_4))
            }), 128)),
            (_ctx.secondOptionGroup != null && _ctx.subheader.length > 1 && _ctx.filteredSecondOptionGroup.length >0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.subheader[1].text), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSecondOptionGroup, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: 'optionItem' + _ctx.item.name + index,
                "data-value": option.uid
              }, _toDisplayString(_ctx.labelProperty ? option[_ctx.labelProperty] : option.text), 9, _hoisted_6))
            }), 128))
          ], 6))
        : (_openBlock(), _createElementBlock("ul", {
            key: 1,
            class: _normalizeClass(["selectlist", { 'is-active': _ctx.listActive }]),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleHideList && _ctx.handleHideList(...args))),
            style: _normalizeStyle(_ctx.selectListStyle)
          }, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('placeholder.noDataFound')), 1)
          ], 6))
    ], 2)
  ]))
}