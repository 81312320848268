export default class ObjectStorage {
    items: any;

    constructor(items : any) {
        if (items === null || items === undefined || !Array.isArray(items)) {
            items = [];
        } else {
            this.items = items;
        }
    }

    add(element:Record<string, unknown>) {
        this.items.push(element);
    }

    /**
     * removes item with called id
     * @param {*} id ... identifiert
     */
    remove(id:number) {
        const removeIndex = this.items.map((item : any) => item.uid).indexOf(id);
        removeIndex != -1 && this.items.splice(removeIndex, 1);
    }

    removeByElement(element : Record<string, unknown>) {
        const index = this.items.indexOf(element);
        if (index > -1) {
            this.items.splice(index, 1);
        }
    }

    removeByIndex(index:number) {
        this.items.splice(index,1);
    }

    /**
     * replace by id of the passed element
     * 
     * @param {Object} source ... source element which replaces element in object storage with same id 
     */
    replaceElement(source: Record<string, unknown>) {
        const editIndex = this.items.map((item:any) => item.uid).indexOf(source.uid);
        this.items.splice(editIndex, 1, source);
    }

    /**
     * 
     * @param {string} key 
     * @param {*} value 
     * @returns {Array} filteredArray with only 
     */
    filterBy(key : string, value : any): Array<any> {
        return this.items.filter((element:any) => element[key] === value);
    }

    /**
     * 
     * @param {string} property
     * @returns {void} 
     */
    sort(property:string): void {
        return this.items.sort((a:any, b:any)  => a[property] - b[property]);
    }

    /**
     * 
     * @param {string} key 
     * @param {*} value 
     * @returns {Array} filteredArray with only 
     */
    findOneBy(key : string, value:any): Array<any> {
        return this.items.find((element : any) => element[key] == value);
    }

    /**
     * 
     * @param {integer} index
     * @returns {Array} filteredArray with only 
     */
    get(index : number) :any {
        return this.items[index];
    }
    
    /**
     * 
     * @returns {Array} filteredArray with only 
     */
    last() :any {
        return this.items[this.items.length-1];
    }

    /**
     * 
     * @returns {integer} length of array
     */
    length(): number {
        return this.items.length;
    }

    clean() : Array<any> {
        return this.items.splice(0, this.items.length);
    }
}