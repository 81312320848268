import axios from "axios";
import { Api } from "@/api/";
import { ObjectStorage, Project } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class ProjectRepository  {
    static projects = new ObjectStorage([]); 

    static async fetchProjects() {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_PROJECTS_ACTION)
        .then((response:any) => {
            //console.log('fetchProjects res', response)
            this.projects = new ObjectStorage(Project.createProjectsFromResponse(response));
            result = this.projects;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getProjectById(uid:any){
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_PROJECT_BY_ID_ACTION + uid)
        .then((response:any) => {
            result = {
                project: Project.createProjectFromResponse(response.data.project)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getProjectsByClient(clientId:any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_PROJECT_BY_CLIENT_ACTION + clientId)
        .then((response:any) => {
            console.log('getProjectsByClient res', response)
            this.projects = new ObjectStorage(Project.createProjectsFromResponse(response));
            result = this.projects;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }
    
    static async createProject(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_PROJECT_ACTION, postData)
        .then((response:any) => {
            console.log("createProject res", response)
            result = {
                project: Project.createProjectFromResponse(response.data.project)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async updateProject(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.UPDATE_PROJECT_ACTION, postData)
        .then((response:any) => {
            console.log("updateProject res", response)
            result = {
                project: Project.createProjectFromResponse(response.data.project)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async toggleProject(postData : string) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.TOGGLE_PROJECT_ACTION, postData)
        .then((response:any) => {
            //console.log("toggleProject res", response)
            result = {
                project: Project.createProjectFromResponse(response.data.project)
            }
            if (result.project) {
                this.projects.replaceElement(result.project as any)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async deleteProject(postData:any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.DELETE_PROJECT_ACTION, postData)
        .then((response:any) => {
            console.log("deleteProject res", response)
            result = {
                project: Project.createProjectFromResponse(response.data.project)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }    
}