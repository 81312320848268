
import { defineComponent } from "vue";

export default defineComponent({
    name: "DateInput",
    data(){
        return {
            modelValue: ''
        }
    },
    props: {
        value: {
            type: String,
            default: null
        },
        min: {
            type: String,
            default: null
        },
        max: {
            type: String,
            default: null
        }
    },
    watch: {
        /* modelValue() {
            console.log('watch modelValue', this.modelValue)
            this.handleChange();
        }, */
        value() {
            //console.log('watch this.value', this.value)
            this.modelValue = this.value
        }
    },
    mounted() {
        //console.log('mounted this.value', this.value)
        this.modelValue = this.value
    },
    methods: {
        handleChange() {
            const v = this.modelValue;
            this.$emit("onChange", v);
        }
    }
})
