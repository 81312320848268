import { createApp  } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "./assets/scss/app.scss"
import './permission.ts'
import { createI18n } from 'vue-i18n';
import translations from "./resources/translations";
import { Converter } from "./utils";
import { wwNotify } from "./plugins";
import { wwConfirm } from "./plugins";

const i18n = createI18n({
    locale: "de",
    fallbackLocale: "de",
    messages: translations
});

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(wwNotify, { dismissErrors: true });
app.use(wwConfirm);
app.mount('#app');
app.use(store);

/*app.config.globalProperties.convertSecondsToTime = function(seconds:any) {
    let hours : any = Math.floor(seconds / 3600);
    let minutes : any = Math.floor((seconds - (hours * 3600)) / 60);
    let timestring = "";
    if (hours < 10) { hours = "0" + hours; }
    timestring += hours + ':';
    if (minutes < 10) { minutes = "0" + minutes; }
    timestring += minutes;
    return timestring + " h";
} */