
import { defineComponent } from 'vue';
import store from "@/store/index";

export default defineComponent({
    name: "PasswordInput",
    props: {
        value: {
            type: String,
            default: ""
        }
    },
    data(){
        return{
            store,
            modelValue: "",
            inputType: "password"
        }
    },
    computed:{
        icon() {
            if(this.inputType=="password") {
                return store.getters.svgIcons.watch;
            } else {
                return store.getters.svgIcons.watchToggle;
            }
        }
    },
    watch:{
        modelValue () {
            this.$emit("onChange", this.modelValue);
        },
        value() {
            this.modelValue = this.value;
        }
    },
    methods:{
        handleToggleInputType() {
            if (this.inputType == "password") {
                this.inputType = "text";
            } else {
                this.inputType = "password";
            }
        }
    }
})
