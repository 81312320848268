
import store from '@/store/index';
import { defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
    name: 'Checkbox',
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return{
            store
        }
    },
    setup(props, { emit }) {
        const modelValue = ref(false);

        onMounted(() => {
            modelValue.value = props.value;
        })

        watch(() => props.value, () => {
            console.log(props.value);
            modelValue.value = props.value;
        })

        return {
            modelValue
        }
    },
    methods: {
        toggleCheckboxState(){
            this.modelValue =! this.modelValue
            this.$emit('onChange', this.modelValue)
        }
    }
})
