
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconButton',
    props: {
        icon: {
            type: String,
            default: ""
        },
        primary:{
            type: Boolean,
            default: false
        },
        raised: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        handleClicked(e : Event){
            e.preventDefault();
            this.$emit('onClick')
        }
    }
})
