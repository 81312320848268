
import store from '@/store/index';
import { defineComponent } from 'vue';
import { IconButton } from '@/ui/index';

export default defineComponent({
    name: "Searchbar",
    components: { IconButton },
    props:{
        storedValue:{
            type: String, 
            default: ''
        },
        setFocus: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            store,
            input: ""
        }
    }, 
    watch:{
        storedValue(){
            this.input = this.storedValue;
        },
        input(){
            this.$emit('onValueChange', this.input);
        }
    }, 
    mounted() {
        if (this.setFocus) {
            this.$nextTick(() => {
                (this.$refs.inputSearch as HTMLElement).focus()
            })
        }
        
    },
    methods: {
        resetSearch() {
            this.input = "";

        }
    }
})
