import { Department } from "./"

export default class Work {
    uid: number;
    title: string;
    description: string;
    number: string;
    defaultHourlyRate:number;
    setupTime:number;
    departments: Department[]

    constructor(uid: number, title: string, description: string, number: string, defaultHourlyRate:number, setupTime:number, departments: Array<Department>) {
        this.uid = uid;
        this.title = title;
        this.description=description;
        this.number = number;
        this.defaultHourlyRate = defaultHourlyRate;
        this.setupTime = setupTime
        this.departments = departments
    }

    static createWorksFromResponse(res:any) {
        const works :any = [];
        res.data.works.forEach((res: any) => {
            const departments = [] as Array<Department>
            if (res.departments) {
                for(const key in res.departments) {
                    departments.push(Department.createDepartmentFromResponse(res.departments[key]))
                }
            }            
            works.push(new Work (res.uid, res.title, res.description, res.number, res.defaultHourlyRate, res.setupTime, departments)
        )})
        return works;
    }
    static createWorkFromResponse(res:any) {
        //console.log('Work[createWorkFromResponse] -> res', res);
        const departments = [] as Array<Department>
        if (res.departments) {
            for(const key in res.departments) {
                departments.push(Department.createDepartmentFromResponse(res.departments[key]))
            }
        }        
        return new Work (res.uid, res.title, res.description, res.number, res.defaultHourlyRate, res.setupTime, departments);
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getTitle() {
        return this.title;
    }

    setTitle(title:string) {
        this.title = title;
    }  

    getDescription() {
        return this.description;
    }

    setDescription(description:string) {
        this.description = description;
    } 
    getNumber() {
        return this.number;
    }

    setNumber(number:string) {
        this.number = number;
    }

    getDefaultHourlyRate() {
        return this.defaultHourlyRate;
    }

    setDefaultHourlyRate(defaultHourlyRate:number) {
        this.defaultHourlyRate = defaultHourlyRate;
    }  

    getSetupTime() {
        return this.setupTime;
    }

    setSetupTime(setupTime:number) {
        this.setupTime = setupTime;
    }  

     getDepartments() {
        return this.departments
     }

     setDepartments(departments:Array<any>) {
         this.departments = departments
     }  
}