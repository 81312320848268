
import { defineComponent } from 'vue';

export default defineComponent({
    name: "List",
    props:{
        isEmpty:{
            type: Boolean, 
            default: false
        },
        emptyStateText: {
            type: String,
            default: ""
        },
        raised:{
            type: Boolean, 
            default: false
        }
    }
})
