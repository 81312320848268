import axios from "axios";
import { Api } from "@/api/";
import { ObjectStorage, Work } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class  WorkRepository {
    static works = new ObjectStorage([]); 

    static async fetchWorks() {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_WORKS_ACTION)
        .then((response:any) => {
            //console.log('fetchWorks res', response)
            this.works = new ObjectStorage(Work.createWorksFromResponse(response));
            result = this.works;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getWorkById(uid:any)  {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_WORK_BY_ID_ACTION + uid)
        .then((response:any) => {
            result = {
                work: Work.createWorkFromResponse(response.data.work)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }
    /**
     * createWork
     * 
     * @param {Object} postData
     * @returns {Object} server response
     */  
    static async createWork(postData : any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_WORK_ACTION, postData)
        .then((response:any) => {
            //console.log("createWork res", response)
            result = {
                work: Work.createWorkFromResponse(response.data.work)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    /**
     * updateWork
     * 
     * @param {Object} postData
     * @returns {Object} server response
     */  
    static async updateWork(postData : any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.UPDATE_WORK_ACTION, postData)
        .then((response:any) => {
            //console.log("createWork res", response)
            result = {
                work: Work.createWorkFromResponse(response.data.work)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async deleteWork(postData:any){
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.DELETE_WORK_ACTION, postData)
        .then((response:any) => {
            //console.log("deleteWork res", response)
            result = {
                work: Work.createWorkFromResponse(response.data.work)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result
    }
}
