
import { defineComponent } from 'vue';
import store from '@/store/index';

export default defineComponent({
    name: "TimiHeader", 
    data(){
        return{
            store
        }
    }
})
