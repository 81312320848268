export default class Client {
    uid: number;
    number : string;
    custName: string;
    active: boolean;
    projects: any;

    constructor(uid: number, number:string, custName: string, active: boolean, projects: any) {
        this.uid = uid;
        this.number = number
        this.custName = custName;
        this.active = active;
        this.projects = projects;
    }

    static createClientsFromResponse(res:any) {
        const clients :any = [];
        res.data.customers.forEach((element: any) => {
            clients.push(new Client (element.uid, element.number, element.custName, element.active, element.projects)
        )})
        return clients;
    }

    static createClientFromResponse(res:any) {
        //console.log('Client[createClientFromResponse] -> res', res);
        return new Client (res.uid, res.number, res.custName, res.active, res.projects);
    }

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }

    getNumber() {
        return this.number;
    }

    setNumber(number:string) {
        this.number = number;
    }

    getCustName() {
        return this.custName;
    }

    setCustName(custName:string) {
        this.custName = custName;
    }

    getActive() {
        return this.active;
    }

    setActive(active:boolean) {
        this.active = active;
    } 
    
    getProjects() {
        return this.projects;
    }

    setProjects(projects:any) {
        this.projects = projects;
    }
}