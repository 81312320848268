import type { App } from "vue";
import type { Options } from './notify.d';
import renderNotify from "./render";

/**
 * **Options:**
 * @property `duration` - Time in milliseconds before hiding the notify notification,
 * set to `0` for an indefinite duration.
 * @property `position` - 'left', 'right', 'top', 'bottom'. 
 * @property `type?: 'error' | 'success'`
 * @property `dismiss?: 'true' | 'false'`
 * @property `dismissErrors?: 'true' | 'false'`
 */
const notifyPlugin = {
    install(app: App, globalOptions: Options ): void {
        const options = globalOptions || {};

        if (options.duration === undefined) {
            options.duration = 5000;
        }

        if (options.position === undefined) {
            options.position = 'top';
        }
        
        renderNotify(app, options);
    }
}

export default notifyPlugin;

