import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  class: "placeholder"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["data-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ww-select-single",
    id: _ctx.cId
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "hidden",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event))
    }, null, 512), [
      [_vModelText, _ctx.modelValue]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["select-single", { 'is-disabled': _ctx.disabled, 'primary': _ctx.primary}]),
      ref: "domCustomSelect"
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(["selected", { 'is-active': _ctx.listActive }]),
        id: 'selectSingleSelect' + _ctx.cId,
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSelectClick && _ctx.handleSelectClick(...args)), ["prevent"])),
        ref: "domSelected"
      }, [
        (!_ctx.selectedText)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.placeholder ? _ctx.placeholder : ' '), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.selectedText), 1)),
        _createElementVNode("i", {
          class: _normalizeClass(["svgicon svgicon--chevrondown", { 'is-active': _ctx.listActive }]),
          innerHTML: _ctx.icons.chevrondown
        }, null, 10, _hoisted_5)
      ], 10, _hoisted_2),
      _createElementVNode("ul", {
        class: _normalizeClass(["selectlist", { 'is-active': _ctx.listActive }]),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSelectListClick && _ctx.handleSelectListClick(...args))),
        style: _normalizeStyle(_ctx.selectListStyle)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions, (option, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: 'optionItem' + _ctx.name + index,
            "data-value": option[_ctx.valueProperty ? _ctx.valueProperty : 'uid']
          }, _toDisplayString(option[_ctx.displayProperty ? _ctx.displayProperty : 'title']), 9, _hoisted_6))
        }), 128))
      ], 6)
    ], 2)
  ], 8, _hoisted_1))
}