export default class User {
    uid: number;
    username: string;
    firstName: string;
    lastName: string;

    constructor(uid: number, username: string, firstName: string, lastName: string ) {
        this.uid = uid;
        this.username = username;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    static createUserFromResponse(res:any) {
        return new User (res.uid, res.username, res.firstName, res.lastName);
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getUsername() {
        return this.username;
    }

    setUsername(username:string) {
        this.username = username;
    }  

    getFirstName() {
        return this.firstName;
    }

    setFirstName(firstName:string) {
        this.firstName = firstName;
    }

    getLastName() {
        return this.lastName;
    }

    setLastName(lastName:string) {
        this.lastName = lastName;
    }  
}
