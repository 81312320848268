import { WorkareaRepository } from "../repository";

const WorkareaController = {
    async fetchWorkareas(){
        const res : any = await WorkareaRepository.fetchWorkareas();
        return res;
    },

    async getWorkAreasByDepartment(departmentId:any){
        const res : any = await WorkareaRepository.getWorkAreasByDepartment(departmentId)
        return res;
    },

    async getWorkareaById(uid:any){
        const res : any = await WorkareaRepository.getWorkareaById(uid)
        return res;
    },

    async createWorkarea(formData : any, departmentId : any) {
        const postData = `number=${formData.number?formData.number:0}&title=${formData.title}&department=${departmentId}`
        const res : any = await WorkareaRepository.createWorkarea(postData)
        return res;
    },

    async updateWorkarea(formData : any, departmentId:any) {
        const postData = `uid=${formData.uid}&departmentId=${departmentId}&title=${formData.title}&number=${formData.number?formData.number:0}`;
        const res : any = await WorkareaRepository.updateWorkarea(postData);
        return res;
    },

    async deleteWorkarea(workareaId : number) {
        const postData = `uid=${workareaId}`;
        const res : any = await WorkareaRepository.deleteWorkarea(postData);
        return res;
    }
}
export default WorkareaController;

