//import { DataService } from '../utils';
import { RightRepository } from '@/repository'
import { SessionController } from '@/controller'
import { Right } from '@/model'

const RightController = {

    async fetchRights() {
        const res : any = await RightRepository.fetchRights();
        return res;
    },

    checkAdmin(): boolean {
        return SessionController.sessionEmployee?.group?.isAdmin == true
    },

    checkRight(identifier: string): boolean {
        if (SessionController.sessionEmployee?.group?.isAdmin) {
            return true
        } else {
            return SessionController.sessionEmployee?.group?.getRightsArray().find((right: Right) => right.identifier == identifier) != null
        }
    }
}
export default RightController;
