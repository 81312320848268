import axios from "axios";
import { Api } from "../api";

const SessionRepository = {
    async getSessionUser(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_SESSION_USER)
            //console.log('getSessionUser res', res)
            return res.data;
        } catch(e){
            console.log('getSessionUser error:', e);
            return {}
        }
    },
    async getSessionCompany(){
        try{
            const res = await axios.get(Api.BACKEND_URL + Api.GET_SESSION_COMPANY)
            //console.log('getSessionCompany res', res)
            return res.data;
        } catch(e){
            console.log('getSessionCompany error:', e);
            return {}
        }
    },
}
export default SessionRepository;