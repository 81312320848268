//import { DataService } from '../utils';
import { TimeEntryRepository } from '@/repository/index.ts';

const TimeEntryController = {
    async fetchTimeEntries(employeeId:number){
        const res : any = await TimeEntryRepository.fetchTimeEntries(employeeId);
        return res
    },
    async fetchDayTimeEntries(employeeId:number, day:number){
        const postData = `employeeId=${employeeId}&day=${day}`
        const res : any = await TimeEntryRepository.fetchDayTimeEntries(postData);
        return res
    },
    async fetchProjectEntries(projectId:number){
        const res : any = await TimeEntryRepository.fetchProjectEntries(projectId);
        return res
    },
    async fetchEmployeeEntries(employeeId:number, from: number | undefined = 0, to: number | undefined = 0) {
        if (from) from = Math.floor(from / 1000)
        if (to) to = Math.floor(to / 1000)
        const postData = `employeeId=${employeeId}&from=${from}&to=${to}`
        const res : any = await TimeEntryRepository.fetchEmployeeEntries(postData);
        return res
    },
    async fetchClientEntries(projectId:number){
        const res : any = await TimeEntryRepository.fetchClientEntries(projectId);
        return res
    },
    async fetchRunningTimeEntries(employeeId:number){
        const res : any = await TimeEntryRepository.fetchRunningTimeEntries(employeeId);
        return res
    },  

    async getTimeEntry(timeEntryId: number){
        const res : any = await TimeEntryRepository.getTimeEntry(timeEntryId);
        return res
    },        

    async createTimeEntry(employeeId:number, workId:number, projectId:number, customerId:number, workstationId:number) {
        const postData = `employeeId=${employeeId}&workId=${workId}&projectId=${projectId}&customerId=${customerId}&workstationId=${workstationId}`
        const response : any = await TimeEntryRepository.createTimeEntry(postData);
        return response
    },
        //[WS TODO] added tstamp
    async toggleTimeEntry(timeentryId:number, employeeId:number, tstamp:number, startstop: string, workId:number, projectId:number, customerId:number, workstationId:number){
        const postData = `timeentryId=${timeentryId}&employeeId=${employeeId}&tstamp=${tstamp}&startstop=${startstop}&workId=${workId}&projectId=${projectId}&customerId=${customerId}&workstationId=${workstationId}`
        const response : any = await TimeEntryRepository.toggleTimeEntry(postData);
        return response
    },
        //[WS TODO] added
    async deleteTimeEntry(timeentryId:number, tstamp:number){
        const postData = `timeentryId=${timeentryId}&tstamp=${tstamp}`
        const response : any = await TimeEntryRepository.deleteTimeEntry(postData);
        console.log('controller response', response)
        return response
    },    

    async updateTimeEntry(timeentryId: number, editTime:number, timeChanged:boolean, tstamp:number, employeeId:number, workId:number, projectId:number, customerId:number, workstationId:number) {
        const postData = `timeentryId=${timeentryId}&editTime=${editTime}&timeChanged=${timeChanged}&employeeId=${employeeId}&tstamp=${tstamp}&workId=${workId}&projectId=${projectId}&customerId=${customerId}&workstationId=${workstationId}`
        const response : any = await TimeEntryRepository.updateTimeEntry(postData);
        return response
    },

}
export default TimeEntryController;

