
import { defineComponent } from 'vue';
import { IconButton, Button } from '@/ui/index';
import { RightController } from '@/controller'
import store from '@/store/index';

export default defineComponent({
    name: "DefaultHeader", 
    components: { IconButton, Button },
    data() {
        return {
            store,
            navActive: false
        }
    },
    methods:{
            //handlers
        handleLogoutUser(){
            this.$emit('handleLogoutUser');
        },
        handleToggleNavClick() {
            this.navActive = !this.navActive
        },
            //functions
        checkIsActive(names : Array<any>){
            if(names.includes(this.$route.name)) {
                return true
            } else {
                return false
            }
        },
        goToDashboard() {
            this.$router.push("/administration/dashboard");
        },
        checkAdmin() {
            return RightController.checkAdmin()
        }        
    }
})
