import { CompanySettings } from "./"

export default class Company {
    uid: number
    title: string
    userMode: number
    settings: CompanySettings | null

    constructor(uid: number, title: string, userMode: number, settings: CompanySettings | null) {
        this.uid = uid
        this.title = title
        this.userMode = userMode
        this.settings = settings
    }

    static createCompanyFromResponse(res:any) {
        const settings = (res.settings ? CompanySettings.createCompanySettingsFromResponse(res.settings) : null)
        return new Company (res.uid, res.title, res.userMode, settings);
    }


    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getTitle() {
        return this.title;
    }

    setTitle(title:string) {
        this.title = title;
    } 
    
    getUserMode() {
        return this.userMode;
    }

    setUserMode(userMode:number) {
        this.userMode = userMode;
    }  

    getSettings() {
        return this.settings;
    }

    setSettings(settings:CompanySettings | null) {
        this.settings = settings;
    }  
}