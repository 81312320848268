
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: "FileUpload",
    emits: ['handleSetImage'],
    props: {
        label:{
            type: String, 
            default: ''
        }
    },
    setup(props, {emit}) {
        const file = ref<File | null>();
        const form = ref<HTMLFormElement>();

        function onFileChanged($event: Event) {
            const target = $event.target as HTMLInputElement;
            if (target && target.files) {
                file.value = target.files[0];
            }
            saveImage();
        }
        
        async function saveImage() {
            if (file.value) {
                try {
                    {
                        emit('handleSetImage', file.value)
                    } 
                } catch (error) {
                    console.error('FileUpload error',error);
                    form.value?.reset();
                    file.value = null;
                }
            }
        }
        return {
            saveImage,
            onFileChanged
        }
    }
})
