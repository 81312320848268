
import { defineComponent } from 'vue';
import { Button } from '@/ui/index';

export default defineComponent({
    name: "AdminHeader", 
    components: { Button },
    methods:{
        handleLogoutUser(){
            this.$emit('handleLogoutUser')
        },
        goToOverview() {
            this.$router.push("/overview");
        }          
    }
})
