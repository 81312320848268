import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  for: "upload-btn",
  class: "label-action"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    ref: "profileImageForm",
    class: _normalizeClass(["ww-file-upload", {'primary': true}])
  }, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("input", {
      type: "file",
      ref: "file",
      id: "upload-btn",
      name: "upload-btn",
      accept: "image/*",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onFileChanged($event))),
      hidden: ""
    }, null, 544)
  ], 512))
}