import axios from "axios";
import { Api } from "../api";
import { ObjectStorage, Workarea } from "@/model/index";
import AxiosErrorService from "@/service/AxiosErrorService";

export default class WorkareaRepository {
    static workareas = new ObjectStorage([]); 

    static async fetchWorkareas(){
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_WORKAREAS_ACTION)
        .then((response:any) => {
         console.log('fetchWorkareas res', response)
            this.workareas = new ObjectStorage(Workarea.createWorkareasFromResponse(response));
            result = this.workareas;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getWorkAreasByDepartment(departmentId : any){
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_WORKAREA_BY_DEPARTMENT_ACTION + departmentId)
        .then((response:any) => {
         console.log('getWorkAreasByDepartment res', response)
            this.workareas = new ObjectStorage(Workarea.createWorkareasFromResponse(response));
            result = this.workareas;
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async getWorkareaById(uid : any) {
        let result = null;
        await axios.get(Api.BACKEND_URL + Api.GET_WORKAREA_BY_ID_ACTION + uid)
        .then((response:any) => {
            result = {
                workarea: Workarea.createWorkareaFromResponse(response.data.workarea)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async createWorkarea(postData : any){
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_WORKAREA_ACTION, postData)
        .then((response:any) => {
            console.log("createWorkarea res", response)
            result = {
                workarea: Workarea.createWorkareaFromResponse(response.data.workarea)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async updateWorkarea(postData : any){
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.CREATE_WORKAREA_ACTION, postData)
        .then((response:any) => {
            console.log("createWorkarea res", response)
            result = {
                workarea: Workarea.createWorkareaFromResponse(response.data.workarea)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result;
    }

    static async deleteWorkarea(postData:any) {
        let result = null;
        await axios.create().post(Api.BACKEND_URL + Api.DELETE_WORKAREA_ACTION, postData)
        .then((response:any) => {
            console.log("deleteWorkarea res", response)
            result = {
                workarea: Workarea.createWorkareaFromResponse(response.data.workarea)
            }
        }).catch((error) => {
            result = AxiosErrorService.handleError(error).error.data;
        });
        return result
    }  
}
