export default class Workstation {
    uid: number;
    title: string;
    department : any;
    unit : any;
  
    constructor(uid: number, title: string, department : any, unit : any) {
        this.uid = uid;
        this.title = title;
        this.department = department;
        this.unit = unit;
    
    }

    
    static createWorkstationsFromResponse(res:any) {
        const workstations :any = [];
        res.data.workstations.forEach((element: any) => {
            workstations.push(new Workstation (element.uid, element.title, element.department, element.unit)
        )})
        return workstations;
    }

    static createWorkstationFromResponse(res:any) {
        //console.log('Workstation[createWorkstationFromResponse] -> res', res);
        return new Workstation (res.uid, res.title, res.department, res.unit);
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }

    setUid(uid:number) {
        this.uid = uid;
    }  

    getTitle() {
    return this.title;
    }

    setTitle(title:string) {
        this.title = title;
    }

    getDepartment() {
        return this.department;
    }

    setDepartment(department:string) {
        this.department = department;
    }
}