
import { defineComponent } from "vue";
import store from "@/store/index";

export default defineComponent({
    name: "Sidebar",
    data(){
        return{
            store
        }
    },
    methods: {
        checkIsActive(names : Array<any>){
            if(names.includes(this.$route.name)) {
                return true
            } else {
                return false
            }
        }
    }
})
