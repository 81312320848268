import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timi-trackingview-nav" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["tracking-view-navitem", {'active': _ctx.checkActivView('/overview')}])
    }, [
      _createVNode(_component_router_link, { to: "/overview" }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "svgicon",
            innerHTML: _ctx.store.getters.svgIcons.timi
          }, null, 8, _hoisted_2),
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('route.overview')), 1)
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["tracking-view-navitem", {'active': _ctx.checkActivView('/projects')}])
    }, [
      _createVNode(_component_router_link, { to: "/projects" }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "svgicon",
            innerHTML: _ctx.store.getters.svgIcons.projects
          }, null, 8, _hoisted_3),
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('route.projects')), 1)
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["tracking-view-navitem", {'active': _ctx.checkActivView('/dailyreport')}])
    }, [
      _createVNode(_component_router_link, { to: "/dailyreport" }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "svgicon",
            innerHTML: _ctx.store.getters.svgIcons.currentDay
          }, null, 8, _hoisted_4),
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('route.youDay')), 1)
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["tracking-view-navitem", {'active': _ctx.checkActivView('/profile')}])
    }, [
      _createVNode(_component_router_link, { to: "/profile" }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: "svgicon",
            innerHTML: _ctx.store.getters.svgIcons.profile
          }, null, 8, _hoisted_5),
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('route.profile')), 1)
        ]),
        _: 1
      })
    ], 2)
  ]))
}