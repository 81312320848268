//import { DataService } from '../utils';
import { LoginRepository } from '@/repository';
import { SessionController } from '@/controller';
import { DataService } from '../service';
import { useEventBus } from '@vueuse/core'
import { appEventKey } from '@/bus/eventkey'

const LoginController = {
    eventBus: useEventBus(appEventKey),

    async getStoragePid(){
        const response : any = await LoginRepository.getStoragePid();
        return response
    },
    async loginUser(userData : any){
        userData = DataService.stringify(userData);
        const response : any = await LoginRepository.loginUser(userData);
        console.log('LoginController.loginUser: response', response)
        return response
    },
    async logoutUser(){
        const response : any = await LoginRepository.logoutUser();
        SessionController.destroySession()
        console.log('LoginController.logoutUser: response', response)
        this.eventBus.emit('onUser')
        return response
    },
   
}
export default LoginController;

