import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ww-card" }
const _hoisted_2 = {
  key: 0,
  class: "ww-card-header"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["ww-card-content", {'is-loading-content': _ctx.contentLoading }])
    }, [
      (_ctx.contentLoading)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "svgicon is-loading-content",
            innerHTML: _ctx.store.getters.svgIcons.loader
          }, null, 8, _hoisted_3))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ], 2)
  ]))
}