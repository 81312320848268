
import { defineComponent } from "vue";
import { SelectSingle } from '@/ui';

export default defineComponent({
    name: "UiTimeSelect",
    components: { SelectSingle },
    data(){
        return {
            modelValue: {
                hour: 0,
                minute: 0
            },
        }
    },
    computed: {
        hours() {
            return [...Array(24).keys()].map(x => {
                return { title: x < 10 ? '0'+x : x, uid: x}
            }).filter(x => x.uid >= this.minHour && x.uid <= this.maxHour);
        },
        minutes() {
            return [...Array(60).keys()].map(x => {
                return { title: x < 10 ? '0'+x : x, uid: x}
            }).filter(x => (x.uid >= this.minMinute && x.uid <= this.maxMinute && x.uid % this.stepMinute == 0));
        },
    },     
    props: {
        value: {
            type: Number,
            default: null
        },
        minHour: {
            type: Number,
            default: 0
        },
        maxHour: {
            type: Number,
            default: 23
        },
        minMinute: {
            type: Number,
            default: 0
        },
        maxMinute: {
            type: Number,
            default: 60
        },
        stepMinute: {
            type: Number,
            default: 1
        },
    },
    watch: {
        /* modelValue() {
            console.log('watch modelValue', this.modelValue)
            this.handleChange();
        }, */
        value() {
            //console.log('watch value', this.value)
            //this.modelValue = this.value
        }

    },
    mounted() {
        //console.log('TimeInput mounted', this.value)
        this.setModelValues()
    },
    methods: {
        handleChangeHour(value: number) {
            this.modelValue.hour = value
            this.handleChange();
        },
        handleChangeMinute(value: number) {
            this.modelValue.minute = value
            this.handleChange();
        },        
        handleChange() {
            this.$emit("onChange", {
                'text': (this.modelValue.hour < 10 ? '0' + this.modelValue.hour : this.modelValue.hour) + ':' + (this.modelValue.minute < 10 ? '0' + this.modelValue.minute : this.modelValue.minute),
                'ms': this.modelValue.hour*60*60*1000 + this.modelValue.minute*60*1000,
                'sec': this.modelValue.hour*60*60 + this.modelValue.minute*60 
            })
        },
        setModelValues() {
            if (this.value) {
                const hours = Math.floor(this.value / 60 / 60)
                const mins = (this.value - Math.floor(hours * 60 * 60)) / 60
                //console.log('TimeInput setModelValue', mins, hours)
                this.modelValue.hour = hours
                this.modelValue.minute = mins
            }
        }
    }
})
