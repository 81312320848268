export default class Address {
    uid: number;
    address: string;
    zip: string;
    city: string;

    constructor(uid:number, address:string, zip:string, city:string)
    {
        this.uid = uid
        this.address = address
        this.zip = zip
        this.city = city
    }

    static createAddressesFromResponse(res:any)
    {
        const addresses :any = [];
        res.forEach((element: any) => {
            addresses.push(new Address(element?.uid, element?.address, element?.zip, element?.city)
        )})
        return addresses;
    }

    static createAddressFromResponse(res:any) {
        return new Address(res?.uid, res?.address, res?.zip, res?.city);
    }
    
    /**** getters & setters ****/

    getUid() {
        return this.uid;
    }
 
     setUid(uid:number) {
         this.uid = uid;
     }  

     getAddress() {
        return this.address;
     }
 
     setAddress(address:string) {
         this.address = address;
     }  

     getZip() {
        return this.zip;
     }
 
     setZip(zip:string) {
         this.zip = zip;
     }  

     getCity() {
        return this.city;
     }
 
     setCity(city:string) {
         this.city = city;
     }   

}